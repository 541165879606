import React from 'react';
import s from './CarouselSection.module.scss';
import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';
import CarouselItem from './CarouselItem/CarouselItem';
import { ICarouselElement } from '../HomePage';
import { addStrapiUrl } from 'utils/strapiUtils';

type Props = { elements?: ICarouselElement[] };

const responsive = {
    superLargeDesktop: {
        breakpoint: { min: 0, max: 4000 },
        items: 1,
    },
};

const CarouselSection = ({ elements }: Props) => {
    if (!elements) return null;

    return (
        <div className={s.carouselContainer}>
            <Carousel
                autoPlay={elements.length > 1}
                autoPlaySpeed={4000}
                infinite
                responsive={responsive}
                arrows={false}
                pauseOnHover
                showDots={elements.length > 1}
                dotListClass={s.dots}
                draggable={false}
            >
                {elements.map((element) => (
                    <CarouselItem
                        key={element.id}
                        text={element.attributes.text}
                        imageUrl={addStrapiUrl(element.attributes.image.data.attributes.url)}
                        imageAlt={element.attributes.image.data.attributes.alternativeText}
                        logoUrl={
                            element.attributes.logo.data && addStrapiUrl(element.attributes.logo.data?.attributes.url)
                        }
                        logoAlt={element.attributes.logo.data?.attributes.alternativeText}
                        link={element.attributes.button_link}
                        buttonText={element.attributes.button_text}
                    />
                ))}
            </Carousel>
        </div>
    );
};

export default CarouselSection;
