import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
    query GetProductCategories($locale: I18NLocaleCode!) {
        productCategories(pagination: { limit: -1 }, locale: $locale) {
            data {
                id
                attributes {
                    name
                    url
                    subcategories(pagination: { limit: -1 }) {
                        data {
                            id
                            attributes {
                                name
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_NAVBAR_DATA = gql`
    query GetNavbarData($locale: I18NLocaleCode!) {
        navbar(locale: $locale) {
            data {
                id
                attributes {
                    logo {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    email
                    phone
                }
            }
        }
    }
`;
