import React from 'react';
import s from './OurOfferSection.module.scss';
import Section from 'components/Section/Section';
import OfferCard from './OfferCard/OfferCard';

import financeImage from 'assets/offer-images/offer-svg/financing.svg';
import discountsImage from 'assets/offer-images/offer-svg/discounts.svg';
import eventsImage from 'assets/offer-images/offer-svg/events.svg';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import { useTranslation } from 'react-i18next';

interface Props {}

const OurOffer = (props: Props) => {
    const { t } = useTranslation('OurOfferSection');

    return (
        <Section>
            <div className={s.container}>
                <OfferCard
                    image={eventsImage}
                    imageAlt={t('EventsImageAlt') || undefined}
                    title={t('EventsTitle')}
                    link={NavigationNames.NewProducts}
                    borderColor='light'
                    borderPostion='left'
                />

                <OfferCard
                    image={financeImage}
                    imageAlt={t('FinanceImageAlt') || undefined}
                    title={t('FinanceTitle')}
                    link={NavigationNames.Financing}
                    borderColor='dark'
                    borderPostion='center'
                />

                <OfferCard
                    image={discountsImage}
                    imageAlt={t('DiscountsImageAlt') || undefined}
                    title={t('DiscountsTitle')}
                    link={NavigationNames.SpecialOffers}
                    borderColor='light'
                    borderPostion='right'
                />
            </div>
        </Section>
    );
};

export default OurOffer;
