import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import Product from 'pages/ProductsPage/Product/Product';
import ProductCategory from 'pages/ProductsPage/ProductCategory/ProductCategory';
import ProductSubcategory from 'pages/ProductsPage/ProductSubcategory/ProductSubcategory';
import ProductsPage from 'pages/ProductsPage/ProductsPage';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

type Props = { usedProducts?: boolean };

const ProductsRouter = ({ usedProducts = false }: Props) => {
    return (
        <Routes>
            <Route path='/' element={<ProductsPage usedProducts={usedProducts} />} />
            <Route path={`:categoryId`} element={<ProductCategory usedProducts={usedProducts} />} />
            <Route path={`:categoryId/:subcategoryId`} element={<ProductSubcategory usedProducts={usedProducts} />} />
            <Route path={`:categoryId/:subcategoryId/:productId`} element={<Product isUsed={usedProducts} />} />

            <Route path='*' element={<NotFoundPage />} />
        </Routes>
    );
};

export default ProductsRouter;
