import React from 'react';
import s from './ProductSidebar.module.scss';
import FeaturedProducts from './FeaturedProducts/FeaturedProducts';
import { useTranslation } from 'react-i18next';
import { GetProductByIdQuery } from 'gql/graphql';

interface Props {
    accessories: NonNullable<
        NonNullable<NonNullable<GetProductByIdQuery['product']>['data']>['attributes']
    >['accessories'];
    relatedProducts: NonNullable<
        NonNullable<NonNullable<GetProductByIdQuery['product']>['data']>['attributes']
    >['related_products'];
}

const ProductSidebar = ({ accessories, relatedProducts }: Props) => {
    const { t } = useTranslation('Product');

    return (
        <div className={s.container}>
            {accessories && accessories.data.length > 0 && (
                <FeaturedProducts title={t('accessories')} products={accessories} />
            )}
            {relatedProducts && relatedProducts.data.length > 0 && (
                <FeaturedProducts title={t('relatedProducts')} products={relatedProducts} />
            )}
        </div>
    );
};

export default ProductSidebar;
