import { gql, useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import Layout from 'components/Layout/Layout';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import Loading from 'components/Loading/Loading';
import SubPage from 'components/SubPage/SubPage';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ISpecialOffer } from 'types/types';
import SpecialOfferCard from './SpecialOfferCard/SpecialOfferCard';
import s from './SpecialOffersPage.module.scss';
import { LanguageContext } from 'contexts/LanguageContext';
import { useTranslation } from 'react-i18next';
import { GetSpecialOffersQuery } from 'gql/graphql';

type Props = {};

const GET_SPECIAL_OFFERS = gql`
    query GetSpecialOffers($specialOffer: Boolean!, $locale: I18NLocaleCode!) {
        products(filters: { special_offer: { eq: $specialOffer } }, locale: $locale) {
            data {
                id
                attributes {
                    name
                    special_offer_name
                    tax_text
                    special_offer_price
                    price_net
                    price_gross
                    price_currency
                    url
                    images {
                        data {
                            id
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    subcategory {
                        data {
                            id
                            attributes {
                                url
                                category {
                                    data {
                                        id
                                        attributes {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const SpecialOffersPage = (props: Props) => {
    const { t } = useTranslation('SpecialOffersPage');
    const { language } = useContext(LanguageContext);
    const { loading, data } = useQuery<GetSpecialOffersQuery>(GET_SPECIAL_OFFERS, {
        variables: { specialOffer: true, locale: language.localeCode },
    });

    return (
        <Layout>
            {loading && <Loading />}

            {!loading && !data?.products?.data?.length && (
                <SubPage title={t('page title').toString()}>
                    <div className={s.noOffersContainer}>
                        <h2 className={s.noOffersTitle}>{t('no offers found')}</h2>
                        <Link to={NavigationNames.Products}>
                            <Button variant='contained'>{t('products')}</Button>
                        </Link>
                    </div>
                </SubPage>
            )}

            {!loading && !!data?.products?.data?.length && (
                <SubPage title={t('page title').toString()}>
                    <ul className={s.offersList}>
                        {data.products.data.map((product) => (
                            <SpecialOfferCard product={product} />
                        ))}
                    </ul>
                </SubPage>
            )}
        </Layout>
    );
};

export default SpecialOffersPage;
