import React from 'react';
import s from './DownloadsModal.module.scss';
import { Modal } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { addStrapiUrl } from 'utils/strapiUtils';
import { useTranslation } from 'react-i18next';
import { GetProductByIdQuery, GetUsedProductByIdQuery } from 'gql/graphql';

interface Props {
    isOpen: boolean;
    onClose: (value: boolean) => void;
    downloadFiles:
        | NonNullable<NonNullable<NonNullable<GetProductByIdQuery['product']>['data']>['attributes']>['download_files']
        | NonNullable<
              NonNullable<NonNullable<GetUsedProductByIdQuery['usedProduct']>['data']>['attributes']
          >['download_files'];
}

const DownloadsModal = ({ isOpen, onClose, downloadFiles }: Props) => {
    const { t } = useTranslation('Product');

    const handleFileDownload = (fileUrl: string) => {
        window.open(addStrapiUrl(fileUrl));
    };

    return (
        <Modal className={s.modal} open={isOpen} onClose={onClose}>
            <div className={s.container}>
                <h5 className={s.title}>{t('filesToDownload')}</h5>
                <ul className={s.filesList}>
                    {downloadFiles?.data.map((file) => {
                        if (!file?.attributes?.url) return null;
                        return (
                            <li
                                className={s.file}
                                onClick={() => handleFileDownload(file.attributes?.url ?? '')}
                                key={file.id}
                            >
                                <FontAwesomeIcon className={s.fileIcon} icon={faFile} size={'2x'} />
                                <span className={s.fileCaption}>
                                    {file.attributes?.caption ?? file.attributes?.name}
                                </span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Modal>
    );
};

export default DownloadsModal;
