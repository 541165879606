import React from 'react';
import s from './CategorySelect.module.scss';
import CategoryCard from './CategoryCard/CategoryCard';
import { IProductCategory } from 'types/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import BeanButton from 'components/BeanButton/BeanButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NewUsedCategoryCard from './NewUsedCategoryCard/NewUsedCategoryCard';
import { addStrapiUrl } from 'utils/strapiUtils';

interface Props {
    description?: string;
    categories: IProductCategory[];
    showNewUsedButtons?: boolean;
}

const CategoriesSection = (props: Props) => {
    const { description, categories, showNewUsedButtons = false } = props;

    const { t } = useTranslation('CategorySelect');
    const navigate = useNavigate();

    const handleReturnClick = (): void => {
        navigate(-1);
    };

    return (
        <div className={s.container}>
            <ul className={s.categoriesList}>
                {categories &&
                    categories.map(({ attributes, id }) => {
                        if (!(attributes.name && attributes.image && attributes.url)) return null;

                        if (showNewUsedButtons) {
                            return (
                                <NewUsedCategoryCard
                                    id={id}
                                    category={attributes.name}
                                    image={addStrapiUrl(attributes.image.data.attributes.url)}
                                    url={attributes.url}
                                    key={attributes.url}
                                />
                            );
                        } else {
                            return (
                                <CategoryCard
                                    categoryId={id}
                                    category={attributes.name}
                                    image={attributes.image}
                                    key={attributes.url}
                                />
                            );
                        }
                    })}
            </ul>

            <div className={s.returnButtonContainer}>
                <BeanButton
                    text={t('returnButtonText')}
                    onClick={handleReturnClick}
                    color='blue'
                    variant='outlined'
                    iconStart={<FontAwesomeIcon icon={faAngleLeft} />}
                />
            </div>

            {description && <div className={s.description}>{description}</div>}
        </div>
    );
};

export default CategoriesSection;
