import React, { useEffect, useState } from 'react';
import s from './LandingSection.module.scss';
import BeanButton from 'components/BeanButton/BeanButton';
import { addStrapiUrl } from 'utils/strapiUtils';
import { LandingMedia } from '../HomePage';

type Props = { media?: LandingMedia };

const LandingSection = ({ media }: Props) => {
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        const MOBILE_KEYWORDS: string[] = ['mobile', 'android', 'ios', 'iphone', 'ipad'];
        const userAgent: string = navigator.userAgent.toLowerCase();

        setIsMobile(MOBILE_KEYWORDS.some((keyword) => userAgent.includes(keyword)));
    }, []);

    if (!media) return null;

    return (
        <section className={s.container}>
            {/* video desktop */}
            {media.attributes.content.data.attributes.mime.includes('video') && !isMobile && (
                <video
                    poster={addStrapiUrl(media.attributes.video_poster.data?.attributes.url)}
                    src={addStrapiUrl(media.attributes.content.data.attributes.url)}
                    autoPlay
                    muted
                    loop
                    playsInline
                    className={`${s.video}`}
                />
            )}

            {/* video mobile if poster is unset */}
            {media.attributes.content.data.attributes.mime.includes('video') &&
                isMobile &&
                !media.attributes.video_poster.data && (
                    <video
                        src={addStrapiUrl(media.attributes.content.data.attributes.url)}
                        autoPlay
                        muted
                        loop
                        playsInline
                        className={`${s.video}`}
                    />
                )}

            {/* video poster on mobile */}
            {media.attributes.content.data.attributes.mime.includes('video') &&
                isMobile &&
                media.attributes.video_poster.data && (
                    <div
                        className={`${s.image}`}
                        style={{
                            backgroundImage: `url(${addStrapiUrl(media.attributes.video_poster.data.attributes.url)})`,
                        }}
                    />
                )}

            {/* image */}
            {media.attributes.content.data.attributes.mime.includes('image') && (
                <div
                    className={`${s.image}`}
                    style={{
                        backgroundImage: `url(${addStrapiUrl(media.attributes.content.data.attributes.url)})`,
                    }}
                />
            )}

            {media.attributes.show_text && (
                <div className={`${s.textContainer}`}>
                    {media.attributes.image_over_text.data && (
                        <img
                            className={s.imageOverText}
                            src={addStrapiUrl(media.attributes.image_over_text.data.attributes.url)}
                            alt={media.attributes.image_over_text.data.attributes.alternativeText}
                        />
                    )}
                    <span className={s.title}>{media.attributes.main_text}</span>
                    <p className={s.description}>{media.attributes.secondary_text}</p>

                    {media.attributes.cta_link && media.attributes.cta_link && (
                        <BeanButton
                            text={media.attributes.cta_text}
                            link={media.attributes.cta_link}
                            color='blue-gradient'
                        />
                    )}
                </div>
            )}
        </section>
    );
};

export default LandingSection;
