import BeanButton from 'components/BeanButton/BeanButton';
import React from 'react';
import s from './CarouselItem.module.scss';
import RoundImage from 'components/RoundImage/RoundImage';

type Props = {
    text: string;
    link?: string;
    buttonText?: string;
    logoUrl?: string;
    logoAlt?: string;
    imageUrl: string;
    imageAlt?: string;
};

const CarouselItem = ({ imageUrl, logoUrl, text, link, buttonText, logoAlt, imageAlt }: Props) => {
    return (
        <div className={s.container}>
            <div className={s.content}>
                <RoundImage className={s.image} imageSrc={imageUrl} imageAlt={imageAlt} />

                <div className={s.textContainer}>
                    {logoUrl && <img src={logoUrl} alt={logoAlt} className={s.smallImage} />}
                    <p className={s.description}>{text}</p>
                    {link && buttonText && (
                        <BeanButton
                            className={s.ctaButton}
                            link={link}
                            text={buttonText}
                            variant='outlined'
                            color='white'
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default CarouselItem;
