import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import Loading from 'components/Loading/Loading';
import React, { useState } from 'react';
import s from './EventsList.module.scss';
import EventsListItem from './EventsListItem/EventsListItem';
import Pagination from '@mui/material/Pagination';
import { Stack } from '@mui/material';
import { GetEventsQuery } from 'gql/graphql';

type Props = {};

const GET_EVENTS = gql`
    query GetEvents($page: Int, $pageSize: Int) {
        events(pagination: { page: $page, pageSize: $pageSize }, sort: "display_order:asc") {
            meta {
                pagination {
                    total
                }
            }
            data {
                id
                attributes {
                    title
                    description
                    date_start
                    date_end
                    image {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                }
            }
        }
    }
`;

const EventsList = (props: Props) => {
    const [page, setPage] = useState(1); // Current page state
    const pageSize = 5; // Set the number of items per page

    const { loading, error, data } = useQuery<GetEventsQuery>(GET_EVENTS, {
        variables: { page, pageSize },
    });
    const events = data?.events?.data;
    const totalEvents = data?.events?.meta?.pagination?.total || 0;
    const totalPages = Math.ceil(totalEvents / pageSize); // Calculate total number of pages

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value); // Update the page state when the user changes the page
    };

    if (loading) {
        return <Loading />;
    }

    if (!loading && !events) {
        return <h2 className={s.noEvents}>Keine neuen News</h2>;
    }

    return (
        <div className={s.container}>
            <ul className={s.list}>
                {events?.map((event) => (
                    <EventsListItem event={event} key={event.id} />
                ))}
            </ul>
            <Stack spacing={2}>
                <Pagination count={totalPages} page={page} onChange={handleChange} color='primary' />
            </Stack>
        </div>
    );
};

export default EventsList;
