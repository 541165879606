import React, { useContext } from 'react';
import s from './Header.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import SocialMediaLinks from 'components/SocialMediaLinks/SocialMediaLinks';
import { Autocomplete, Box, TextField } from '@mui/material';
import { ILanguage, LANGUAGES, LanguageContext } from 'contexts/LanguageContext';
import { useTranslation } from 'react-i18next';
import { NavbarResponse } from 'types/strapi/Navbar';
import SearchDialogButton from 'components/SearchDialog/SearchDialogButton';

interface Props {
    navData: NavbarResponse | undefined;
    navLoading: boolean;
    navError: any;
}
const Header = ({ navData, navLoading, navError }: Props) => {
    const { t } = useTranslation('Header');
    const { language, changeLanguage } = useContext(LanguageContext);

    const phone = navData?.navbar.data.attributes.phone;
    const email = navData?.navbar.data.attributes.email;

    return (
        <header className={s.header}>
            <div className={s.contacts}>
                {phone && (
                    <div className={s.contactContainer}>
                        <FontAwesomeIcon icon={faPhone} className={s.icon} />
                        <a href={`tel:${phone.replace(/\s/g, '')}`} className={s.phone}>
                            {phone}
                        </a>
                    </div>
                )}
                {email && (
                    <div className={s.contactContainer}>
                        <FontAwesomeIcon icon={faEnvelope} className={s.icon} />
                        <a href={`mailto:${email}`} className={s.email}>
                            {email}
                        </a>
                    </div>
                )}
            </div>

            <div className={s.rightContainer}>
                <SearchDialogButton />
                <SocialMediaLinks color='black' />
                <Autocomplete
                    id='langauge-select'
                    disablePortal
                    size='small'
                    value={LANGUAGES.find((option) => option.name === language.name)}
                    options={LANGUAGES}
                    sx={{ minWidth: '150px' }}
                    getOptionLabel={(option) => option.name}
                    disableClearable
                    onChange={(event: any, newValue: ILanguage) => {
                        changeLanguage(newValue);
                    }}
                    renderOption={(props, option) => (
                        <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            <img
                                loading='lazy'
                                width='20'
                                src={`https://flagcdn.com/w20/${option.flagCode.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.flagCode.toLowerCase()}.png 2x`}
                                alt=''
                            />
                            {option.name}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t('language-select-label')}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                            }}
                        />
                    )}
                />
            </div>
        </header>
    );
};

export default Header;
