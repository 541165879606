import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Dynamically load all translation files from the locales folder
function loadTranslations() {
    const context = require.context('./locales', true, /\.json$/);
    const resources: { [key: string]: { [key: string]: any } } = {};

    context.keys().forEach((key) => {
        // Extract the language and namespace from the file path
        const pathParts = key.split('/');
        const lang = pathParts[1]; // 'en', 'de'
        const namespace = pathParts[2].split('.')[0]; // 'translation', 'contact', 'home', etc.

        if (!resources[lang]) {
            resources[lang] = {};
        }

        // Load the translations and add them to the resources object
        resources[lang][namespace] = context(key);
    });

    return resources;
}

const resources = loadTranslations();

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'de',
        lng: 'de', // default language
        debug: false,
        resources: resources, // Dynamic translations loaded
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
