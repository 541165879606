import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import SearchDialog from './SearchDialog';

type Props = {
    color?: 'primary' | 'secondary' | 'default' | 'inherit' | 'error' | 'info' | 'success' | 'warning';
    size?: 'small' | 'medium' | 'large';
};

const SearchDialogButton = ({ color, size }: Props) => {
    const [searchDialogOpen, setSearchDialogOpen] = useState<boolean>(false);

    const handleSearchButtonClick = () => {
        setSearchDialogOpen(true);
    };

    return (
        <>
            <IconButton color={color} size={size} onClick={handleSearchButtonClick}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </IconButton>

            <SearchDialog open={searchDialogOpen} handleClose={() => setSearchDialogOpen(false)} />
        </>
    );
};

export default SearchDialogButton;
