import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { addStrapiUrl } from 'utils/strapiUtils';
import s from './SpecialOfferCard.module.scss';
import { useTranslation } from 'react-i18next';
import { ProductCondition } from 'utils/productConditionEnum';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import { GetSpecialOffersQuery } from 'gql/graphql';

type Props = {
    product: NonNullable<GetSpecialOffersQuery['products']>['data'][0];
};

const SpecialOfferCard = ({ product }: Props) => {
    const CATEGORY_URL = product.attributes?.subcategory?.data?.attributes?.category?.data?.id;
    const SUBCATEGORY_URL = `${ProductCondition.New}-${product.attributes?.subcategory?.data?.id}`;

    const PRODUCT_URL = product.attributes
        ? `${NavigationNames.Products}/${CATEGORY_URL}/${SUBCATEGORY_URL}/${product.id}`
        : '#';

    const { t } = useTranslation('SpecialOfferCard.tsx');

    return (
        <li className={s.cardContainer}>
            <div className={s.imageContainer}>
                <img
                    className={s.image}
                    src={addStrapiUrl(product.attributes?.images?.data[0]?.attributes?.url ?? '')}
                    alt={addStrapiUrl(product.attributes?.images?.data[0]?.attributes?.alternativeText ?? '')}
                />
            </div>
            <div className={s.content}>
                <h3 className={s.name}>{product.attributes?.special_offer_name || product.attributes?.name}</h3>
                <div className={s.contentBottom}>
                    {product.attributes?.special_offer_price && (
                        <div className={s.prices}>
                            {/* {product.attributes.price_gross && (
                                <p className={s.priceOriginal}>
                                    {product.attributes.price_gross} {product.attributes.price_currency}
                                </p>
                            )} */}

                            <p className={s.priceNew}>
                                {product.attributes.special_offer_price} {product.attributes.price_currency}
                            </p>

                            {product.attributes.tax_text && (
                                <span className={s.noVAT}>{product.attributes.tax_text}</span>
                            )}
                        </div>
                    )}
                    <Link to={PRODUCT_URL}>
                        <Button variant='contained' sx={{ width: '100%' }}>
                            {t('details')}
                        </Button>
                    </Link>
                </div>
            </div>
        </li>
    );
};

export default SpecialOfferCard;
