import React, { Suspense } from 'react';
import './App.scss';

import { Routes, Route, BrowserRouter } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';
import apolloClient from '../apollo/apollo.config.js';

// mui
import { ThemeProvider } from '@mui/material/styles';
import theme from 'mui/theme';

//  Pages
import HomePage from 'pages/HomePage/HomePage';
import Contact from 'pages/ContactPage/ContactPage';
import ProductsPage from 'pages/ProductsPage/ProductsPage';
import NotFound from 'pages/NotFoundPage/NotFoundPage';
import ServicePage from 'pages/ServicePage/ServicePage';
import AboutUsPage from 'pages/AboutUsPage/AboutUsPage';

// Components
import ScrollTop from 'components/ScrollTop/ScrollTop';
import EventsPage from 'pages/EventsPage/EventsPage';
import FinancePage from 'pages/FinancePage/FinancePage';
import SpecialOffersPage from 'pages/SpecialOffersPage/SpecialOffersPage';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import LegalNoticePage from 'pages/LegalNoticePage/LegalNoticePage';
import TermsOfSalePage from 'pages/TermsOfSalePage/TermsOfSalePage';
import RodoPage from 'pages/RodoPage/RodoPage';
import NewProductsPage from 'pages/NewProductsPage/NewProductsPage';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import LanguageProvider from 'contexts/LanguageContext';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import ProductsRouter from 'routing/ProductsRouter';

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <HelmetProvider>
                <BrowserRouter>
                    <LanguageProvider>
                        <ApolloProvider client={apolloClient}>
                            <div className='app'>
                                <Helmet>
                                    <title>Optic-Handel Fragstein</title>
                                    <meta
                                        name='description'
                                        content='Fachhandel für Ophthalmologische Geräte und
                                        Werkstattmaschinen'
                                    />
                                </Helmet>
                                <ScrollTop>
                                    <Suspense fallback={<LoadingScreen />}>
                                        <Routes>
                                            <Route index element={<HomePage />} />
                                            <Route
                                                path={`${NavigationNames.Products}/*`}
                                                element={<ProductsRouter />}
                                            />
                                            <Route
                                                path={`${NavigationNames.UsedProducts}/*`}
                                                element={<ProductsRouter usedProducts />}
                                            />
                                            <Route path={NavigationNames.Contact} element={<Contact />} />
                                            <Route path={`${NavigationNames.Service}/*`} element={<ServicePage />} />
                                            <Route path={NavigationNames.AboutUs} element={<AboutUsPage />} />
                                            <Route path={`${NavigationNames.News}/*`} element={<EventsPage />} />
                                            <Route
                                                path={`${NavigationNames.SpecialOffers}/*`}
                                                element={<SpecialOffersPage />}
                                            />
                                            <Route path={`${NavigationNames.Financing}`} element={<FinancePage />} />
                                            <Route
                                                path={`${NavigationNames.LegalNotice}`}
                                                element={<LegalNoticePage />}
                                            />
                                            <Route
                                                path={`${NavigationNames.TermsOfSale}`}
                                                element={<TermsOfSalePage />}
                                            />
                                            <Route path={`${NavigationNames.Rodo}`} element={<RodoPage />} />
                                            <Route
                                                path={`${NavigationNames.NewProducts}`}
                                                element={<NewProductsPage />}
                                            />
                                            <Route path='*' element={<NotFound />} />
                                        </Routes>
                                    </Suspense>
                                </ScrollTop>
                            </div>
                        </ApolloProvider>
                    </LanguageProvider>
                </BrowserRouter>
            </HelmetProvider>
        </ThemeProvider>
    );
};

export default App;
