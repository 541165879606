import { Link, Typography } from '@mui/material';
import React from 'react';
import s from './PersonnelListItem.module.scss';
import DOMPurify from 'dompurify';

type Props = {
    location?: string;
    fullName: string;
    info: string;
};

const PersonnelListItem = ({ location, fullName, info }: Props) => {
    return (
        <li className={s.container}>
            <Typography variant='h6' fontWeight={500} component='h4'>
                {fullName}
            </Typography>
            <div
                className={s.info}
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(info),
                }}
            />
        </li>
    );
};

export default PersonnelListItem;
