import React, { Fragment } from 'react';
import s from './FeaturedProducts.module.scss';
import Divider from '@mui/material/Divider';
import { addStrapiUrl } from 'utils/strapiUtils';
import { Link } from 'react-router-dom';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import { GetProductByIdQuery } from 'gql/graphql';

type Props = {
    products:
        | NonNullable<NonNullable<NonNullable<GetProductByIdQuery['product']>['data']>['attributes']>['accessories']
        | NonNullable<
              NonNullable<NonNullable<GetProductByIdQuery['product']>['data']>['attributes']
          >['related_products'];
    title: string;
};

const FeaturedProducts = ({ products, title }: Props) => {
    return (
        <div className={s.container}>
            <h5 className={s.title}>{title}</h5>
            <div className={s.content}>
                {products?.data.map((product) => {
                    const url = `${NavigationNames.Products}/${product.attributes?.subcategory?.data?.attributes?.category?.data?.id}/${product.attributes?.subcategory?.data?.id}/${product.id}`;

                    return (
                        <Fragment key={product.id}>
                            <Link to={url} className={s.product}>
                                <img
                                    src={addStrapiUrl(product.attributes?.images?.data?.[0]?.attributes?.url ?? '')}
                                    alt={
                                        product.attributes?.images?.data?.[0]?.attributes?.alternativeText ??
                                        'Product Image'
                                    }
                                    className={s.productImage}
                                />
                                <h6 className={s.productName}>{product?.attributes?.name}</h6>
                            </Link>

                            <Divider />
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default FeaturedProducts;
