import React, { useContext } from 'react';
import Layout from 'components/Layout/Layout';
import CategorySelect from './CategorySelect/CategorySelect';
import { gql, useQuery } from '@apollo/client';
import { IProductCategory } from 'types/types';
import SubPage from 'components/SubPage/SubPage';
import backgroundImg from 'assets/subpage-images/products.png';
import { LanguageContext } from 'contexts/LanguageContext';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

interface Props {
    usedProducts: boolean;
}

interface IProductCategoriesData {
    productCategories: {
        data: IProductCategory[];
    };
}

// Should be sorted by order, but its not working on latest strapi 4.25
const GET_CATEGORIES = gql`
    query GetProductCategories($locale: I18NLocaleCode!) {
        productCategories(pagination: { limit: -1 }, sort: "id:asc", locale: $locale) {
            data {
                id
                attributes {
                    name
                    url
                    description
                    image {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                }
            }
        }
    }
`;

const ProductsPage = ({ usedProducts }: Props) => {
    const { t } = useTranslation('ProductsPage');
    const { language } = useContext(LanguageContext);
    const {
        loading,
        data: queryData,
        error,
    } = useQuery<IProductCategoriesData>(GET_CATEGORIES, {
        variables: { locale: language.localeCode },
    });
    return (
        <>
            <Helmet>
                <title>{t(usedProducts ? 'usedProductsPageTitle' : 'newProductsPageTitle')}</title>
            </Helmet>

            <Layout>
                <SubPage
                    loading={loading}
                    title={t(usedProducts ? 'usedProductsPageTitle' : 'newProductsPageTitle') as string}
                    image={backgroundImg}
                >
                    {queryData && <CategorySelect showNewUsedButtons categories={queryData.productCategories.data} />}
                </SubPage>
            </Layout>

            {/* <ConditionFab /> */}
        </>
    );
};

export default ProductsPage;
