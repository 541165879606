import { faArrowLeft, faArrowRight, faClose, faImages, faPlay, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Modal, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { addStrapiUrl } from 'utils/strapiUtils';
import s from './ProductMedia.module.scss';
import { useTranslation } from 'react-i18next';
import { GetProductByIdQuery, GetUsedProductByIdQuery } from 'gql/graphql';

type Props = {
    images:
        | NonNullable<NonNullable<NonNullable<GetProductByIdQuery['product']>['data']>['attributes']>['images']
        | NonNullable<NonNullable<NonNullable<GetUsedProductByIdQuery['usedProduct']>['data']>['attributes']>['images'];
    videos:
        | NonNullable<NonNullable<NonNullable<GetProductByIdQuery['product']>['data']>['attributes']>['videos']
        | NonNullable<NonNullable<NonNullable<GetUsedProductByIdQuery['usedProduct']>['data']>['attributes']>['videos'];
};

const ProductMedia = ({ images, videos }: Props) => {
    const { t } = useTranslation('Product');
    const [selectedImageSrc, setSelectedImageSrc] = useState<string>(
        addStrapiUrl(images.data[0]?.attributes?.url ?? '')
    );
    const [selectedImageAlt, setSelectedImageAlt] = useState<string>(images.data[0]?.attributes?.alternativeText ?? '');
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
    const [isImageOpen, setIsImageOpen] = useState<boolean>(false);

    const [selectedVideoSrc, setSelectedVideoSrc] = useState<string>(videos?.data[0]?.attributes?.url ?? '');
    const [isVideoOpen, setIsVideoOpen] = useState<boolean>(false);

    // states reset after changing product
    useEffect(() => {
        setSelectedImageSrc(addStrapiUrl(images.data[0]?.attributes?.url ?? ''));
        setSelectedImageAlt(images.data[0]?.attributes?.alternativeText ?? '');
        setSelectedImageIndex(0);
        setSelectedMediaType('images');
    }, [images.data]);

    // image navigation
    const changeImage = useCallback(
        (shift: number) => {
            const newImage = images.data[selectedImageIndex + shift];
            if (newImage && newImage.attributes) {
                setSelectedImageSrc(addStrapiUrl(newImage.attributes.url));
            }
            setSelectedImageAlt(images.data[selectedImageIndex + shift]?.attributes?.alternativeText ?? '');
            setSelectedImageIndex(selectedImageIndex + shift);
        },
        [images.data, selectedImageIndex]
    );

    useEffect(() => {
        const handleImageNavigation = (e: KeyboardEvent) => {
            if (e.key === 'ArrowLeft' && selectedImageIndex > 0) {
                changeImage(-1);
            } else if (e.key === 'ArrowRight' && selectedImageIndex < images.data.length - 1) {
                changeImage(1);
            }
        };

        if (isImageOpen) {
            window.addEventListener('keydown', handleImageNavigation);
            return () => window.removeEventListener('keydown', handleImageNavigation);
        }
    }, [isImageOpen, images.data, selectedImageIndex, changeImage]);

    const [selectedMediaType, setSelectedMediaType] = useState<'images' | 'videos'>('images');

    return (
        <div className={s.productMedia}>
            <div className={s.imageContainer}>
                <img
                    src={selectedImageSrc}
                    alt={selectedImageAlt}
                    className={s.image}
                    onClick={() => setIsImageOpen(true)}
                />
            </div>

            <div className={s.mediaCarouselContainer}>
                <ButtonGroup>
                    <Button
                        onClick={() => setSelectedMediaType('images')}
                        variant={selectedMediaType === 'images' ? 'contained' : 'outlined'}
                        disabled={images.data.length === 0}
                        size='small'
                        startIcon={<FontAwesomeIcon icon={faImages} />}
                    >
                        {t('photos')}
                    </Button>
                    <Button
                        onClick={() => setSelectedMediaType('videos')}
                        variant={selectedMediaType === 'videos' ? 'contained' : 'outlined'}
                        disabled={!videos || videos.data.length === 0}
                        size='small'
                        startIcon={<FontAwesomeIcon icon={faVideo} />}
                    >
                        {t('videos')}
                    </Button>
                </ButtonGroup>
                <div className={s.mediaCarousel}>
                    {selectedMediaType === 'images' &&
                        images.data.map((image, index) => (
                            <img
                                src={addStrapiUrl(image.attributes?.url)}
                                alt={image.attributes?.alternativeText ?? ''}
                                className={[
                                    s.carouselImage,
                                    selectedImageSrc === addStrapiUrl(image?.attributes?.url) ? s.selected : null,
                                ].join(' ')}
                                draggable={false}
                                onClick={() => {
                                    setSelectedImageSrc(addStrapiUrl(image.attributes?.url));
                                    setSelectedImageAlt(image.attributes?.alternativeText ?? '');
                                    setSelectedImageIndex(index);
                                }}
                                key={image.id}
                            />
                        ))}

                    {selectedMediaType === 'videos' &&
                        videos?.data.map((video) => (
                            <Tooltip title={video.attributes?.caption} placement={'bottom'} arrow>
                                <div
                                    className={s.carouselVideo}
                                    key={video.id}
                                    onClick={() => {
                                        setSelectedVideoSrc(video.attributes?.url ?? '');
                                        setIsVideoOpen(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlay} size={'2x'} />
                                </div>
                            </Tooltip>
                        ))}
                </div>
            </div>

            <Modal className={s.modal} open={isImageOpen} onClose={() => setIsImageOpen(false)} disableScrollLock>
                <div className={s.modalImageBackground}>
                    <FontAwesomeIcon
                        className={s.modalImageClose}
                        icon={faClose}
                        onClick={() => setIsImageOpen(false)}
                    />
                    {selectedImageIndex !== 0 && (
                        <div
                            className={s.imageNavigationContainerLeft}
                            onClick={() => selectedImageIndex !== 0 && changeImage(-1)}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} className={s.imageArrow} />
                        </div>
                    )}

                    <img className={s.modalImage} src={selectedImageSrc} alt={selectedImageAlt} />

                    {selectedImageIndex < images.data.length - 1 && (
                        <div
                            className={s.imageNavigationContainerRight}
                            onClick={() => selectedImageIndex < images.data.length - 1 && changeImage(1)}
                        >
                            <FontAwesomeIcon icon={faArrowRight} className={s.imageArrow} />
                        </div>
                    )}
                </div>
            </Modal>

            <Modal className={s.modal} open={isVideoOpen} onClose={() => setIsVideoOpen(false)}>
                <video className={s.modalVideo} controls autoPlay>
                    <source src={addStrapiUrl(selectedVideoSrc)} />
                </video>
            </Modal>
        </div>
    );
};

export default ProductMedia;
