import { gql } from '@apollo/client/core';

export const GET_NEW_PRODUCTS_BY_SUBCATEGORY_ID = gql`
    query GetNewProductsBySubcategoryId($subcategoryId: ID!, $manufacturerId: ID, $locale: I18NLocaleCode!) {
        products(
            filters: {
                and: [
                    { subcategory: { id: { eq: $subcategoryId } } }
                    { manufacturer: { id: { eq: $manufacturerId } } }
                ]
            }
            locale: $locale
        ) {
            data {
                id
                attributes {
                    name
                    url
                    is_new
                    images {
                        data {
                            id
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_MANUFACTURERS = gql`
    query GetManufacturers($subcategoryId: ID!) {
        manufacturers(filters: { products: { subcategory: { id: { eq: $subcategoryId } } } }) {
            data {
                id
                attributes {
                    name
                }
            }
        }
    }
`;

export const GET_PRODUCT_SUBCATEGORY = gql`
    query GetProductSubcategory($subcategoryId: ID!, $locale: I18NLocaleCode!) {
        productSubcategory(id: $subcategoryId, locale: $locale) {
            data {
                id
                attributes {
                    name
                    url
                    description
                    image {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    category {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    used_products {
                        data {
                            id
                        }
                    }
                    products {
                        data {
                            id
                        }
                    }
                }
            }
        }
    }
`;
