import React from 'react';
import s from './AboutUsPage.module.scss';
import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

type Props = {};

const AboutUsPage = (props: Props) => {
    const { t, i18n } = useTranslation('AboutUsPage');
    const currentLanguage = i18n.language;

    return (
        <>
            <Helmet>
                <title>{t('pageTitle')}</title>
            </Helmet>
            <Layout>
                <SubPage title={t('pageTitle').toString()} currentPageName={t('pageTitle').toString()}>
                    <div className={s.container}>
                        {currentLanguage === 'de' ? (
                            <>
                                <p className={s.secondaryText}>
                                    Seit 1995 beliefert die Firma Optic-Handel Fragstein mit Sitz in Würselen (bei
                                    Aachen) Augenoptiker und Augenärzte mit ophthalmologischen und optischen Geräten.
                                    Durch unser großes Lager können unsere Kunden auf ein riesiges Sortiment an Neu- und
                                    Gebrauchtgeräten zugreifen.
                                </p>
                                <p className={s.secondaryText}>
                                    Das Lieferprogramm umfasst Kleingeräte, genauso wie ophthalmologische Großgeräte und
                                    Refraktionseinheiten. Durch unsere enge Zusammenarbeit mit den führenden Herstellern
                                    von ophthalmologischen und optischen Geräten können wir fast alle auf dem Markt
                                    befindlichen Geräte anbieten. Zu unseren Partnern zählen unter anderem Rodenstock
                                    Instruments, i-Tronics, Oculus/Nidek, HS Doms, Topcon Medical, iCare, Tomey, Moptim
                                    und Heine.
                                </p>
                                <p className={s.secondaryText}>
                                    Auch für den Umzug Ihrer ophthalmologischen und optischen Geräte sind wir der
                                    richtige Ansprechpartner.
                                </p>
                                <p className={s.secondaryText}>
                                    Anfang August 2011 haben wir den Alleinvertrieb für Rodenstock Instrumente in
                                    Deutschland übernommen und können mittlerweile auf eine mehr als 10-jährige
                                    erfolgreiche Zusammenarbeit zurückblicken.
                                </p>
                                <p className={s.secondaryText}>
                                    Mit 4 Standorten in Europa sind wir auch international gut aufgestellt. Qualitativ
                                    hochwertige Refraktionseinheiten und Instrumententische aus eigener Produktion in
                                    Polen runden unsere Produktpalette ab.
                                </p>
                                <p className={s.secondaryText}>
                                    Unsere spezialisierten Techniker führen die Wartung und Reparaturen von
                                    Kundengeräten aller Art in der eigenen Werkstatt durch.
                                </p>
                            </>
                        ) : (
                            <>
                                <p className={s.secondaryText}>
                                    <strong>Optic-Handel Fragstein</strong> (est. 1995) in Aachen is a renowned supplier
                                    of new and used ophthalmic equipment.
                                </p>
                                <p className={s.secondaryText}>
                                    Our product range includes small appliances, ophthalmologic devices, and refraction
                                    units, catering to the needs of ophthalmologists and opticians alike. Thanks to our
                                    extensive warehouse, we offer our customers access to a wide selection of both new
                                    and used devices.
                                </p>
                                <p className={s.secondaryText}>
                                    Through collaborations with leading manufacturers in the industry, we are able to
                                    provide almost any device available on the market.
                                </p>
                                <p className={s.secondaryText}>
                                    Since August 2011, we have been the exclusive distributor of{' '}
                                    <strong>Rodenstock Instruments</strong> in Germany. The license for Rodenstock
                                    Instruments has been held by <strong>Tomey</strong>, based in Nuremberg, since
                                    January 2011.
                                </p>
                                <p className={s.secondaryText}>
                                    At Optic-Handel Fragstein, we also specialize in appliances for lens processing. As
                                    part of this focus, we have secured exclusive distribution rights for{' '}
                                    <strong>i-Tronics lens edgers</strong> in Germany. These modular systems, featuring
                                    advanced European technology, are highly configurable and provide comprehensive
                                    solutions for lens processing.
                                </p>
                                <p className={s.secondaryText}>
                                    Our team of specialized engineers handles maintenance and repairs for all types of
                                    customer equipment in our in-house workshop.
                                </p>
                                <p className={s.secondaryText}>
                                    Optic-Handel Fragstein actively participates in major trade fairs and congresses,
                                    ensuring our continued presence in the industry.
                                </p>
                            </>
                        )}
                    </div>
                </SubPage>
            </Layout>
        </>
    );
};

export default AboutUsPage;
