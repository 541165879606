import React from 'react';
import s from './PersonnelList.module.scss';
import { Typography } from '@mui/material';

type Props = { name: string; children: React.ReactNode };

const PersonnelList = ({ children, name }: Props) => {
    return (
        <li className={s.container}>
            <Typography className={s.title} variant='h5' fontWeight='500' component='h3'>
                {name}
            </Typography>
            <ul className={s.personnelList}>{children}</ul>
        </li>
    );
};

export default PersonnelList;
