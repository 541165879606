import React from 'react';
import s from './LoadingScreen.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {}

const LoadingScreen = (props: Props) => {
    const { t } = useTranslation('LoadingScreen');
    return (
        <div className={s.container}>
            <div className={s.spinner}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <h1 className={s.text}>{t('loadingText')}</h1>
        </div>
    );
};

export default LoadingScreen;
