import React from 'react';
import s from './CategoryCard.module.scss';
import { Link } from 'react-router-dom';
import { StrapiImageData } from 'types/strapi/Shared';
import { addStrapiUrl } from 'utils/strapiUtils';
import { useTranslation } from 'react-i18next';

interface Props {
    category: string;
    image: StrapiImageData;
    categoryId: number;
    description?: string;
    emptyCategory?: boolean;
}

const CategoryCard = ({ image, category, categoryId, description, emptyCategory = false }: Props) => {
    const { t } = useTranslation('CategoryCard');

    return (
        <li className={`${s.card} ${emptyCategory ? s.emptyCategory : ''}`}>
            {emptyCategory && <div className={s.emptyBadge}>{t('emptyCategory')}</div>}

            <Link to={categoryId.toString()} className={s.link}>
                <div className={s.imageContainer}>
                    <img
                        className={s.image}
                        src={addStrapiUrl(image.data?.attributes?.url) || ''}
                        alt={addStrapiUrl(image.data?.attributes?.alternativeText) || ''}
                    />
                </div>
                <div className={s.content}>
                    <h3 className={s.title}>{category}</h3>
                    {description && <span className={s.description}>{description}</span>}
                </div>
            </Link>
        </li>
    );
};

export default CategoryCard;
