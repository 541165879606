import React, { useContext } from 'react';
import './Layout.scss';
import Header from 'components/Layout/Header/Header';
import Navbar from 'components/Layout/Navbar/Navbar';
import Footer from 'components/Layout/Footer/Footer';
import { useQuery } from '@apollo/client';
import { NavbarResponse } from 'types/strapi/Navbar';
import { GET_NAVBAR_DATA } from './Navbar/Navbar.query';
import { LanguageContext } from 'contexts/LanguageContext';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';

interface Props {
    children?: React.ReactNode;
}

const Layout = (props: Props) => {
    const { language } = useContext(LanguageContext);

    const {
        loading: navLoading,
        error: navError,
        data: navData,
    } = useQuery<NavbarResponse>(GET_NAVBAR_DATA, {
        variables: { locale: language.localeCode },
    });

    if (navLoading) return <LoadingScreen />;

    return (
        <div className='layout'>
            <Header navData={navData} navError={navError} navLoading={navLoading} />
            <Navbar navData={navData} navError={navError} navLoading={navLoading} />
            <main className='layout__content'>{props.children}</main>
            <Footer />
        </div>
    );
};

export default Layout;
