export const STRAPI_URL: string | undefined = process.env.REACT_APP_STRAPI_URL || '';

export const addStrapiUrl = (url: string | undefined) => {
    if (!url) return '';
    return STRAPI_URL + url;
};

export const addCMSUrlToRichText = (text: string) => {
    return text.replaceAll('/uploads', `${STRAPI_URL}/uploads`);
};
