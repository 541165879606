import React, { useState } from 'react';
import s from './Navbar.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import MobileMenu from './MobileMenu/MobileMenu';
import { useTranslation } from 'react-i18next';
import { NavbarResponse } from 'types/strapi/Navbar';
import { addStrapiUrl } from 'utils/strapiUtils';

interface Props {
    navData: NavbarResponse | undefined;
    navLoading: boolean;
    navError: any;
}

export enum NavigationNames {
    Home = '/startseite',
    Products = '/produkte',
    UsedProducts = '/gebrauchte-produkte',
    Service = '/service',
    News = '/news',
    AboutUs = '/uber-uns',
    Contact = '/kontakt',
    SpecialOffers = '/aktionspreise',
    Financing = '/finanzierung',
    LegalNotice = '/rechtliche-hinweise',
    TermsOfSale = '/allgemeine-verkaufsbedingungen',
    Rodo = '/datenschutz-grundverordnung',
    NewProducts = '/neue-produkte',
}

const Navbar = ({ navData, navLoading, navError }: Props) => {
    const { t } = useTranslation('Navbar');
    const { pathname } = useLocation();

    const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
    return (
        <nav className={s.navbar}>
            <Link to='/' className={s.logo}>
                <img
                    alt={addStrapiUrl(navData?.navbar.data.attributes.logo.data.attributes.url) || 'Logo'}
                    src={addStrapiUrl(navData?.navbar.data.attributes.logo.data.attributes.url)}
                    className={s.logoImg}
                />
            </Link>

            <ul className={s.linksList}>
                <li className={s.linksListItem}>
                    <Link
                        to={`${NavigationNames.Products}`}
                        className={`${s.link} ${pathname.includes(NavigationNames.Products) ? s.selected : ''}`}
                    >
                        {t('products')}
                    </Link>

                    {/* {!queriesLoading && categoriesData && (
                        <Dropdown isOpen={isProductsDropdownOpen}>
                            {categoriesData.productCategories.data.map((category: any) => {
                                if (category.attributes.subcategories.data.length !== 0)
                                    return (
                                        <DropdownCategory
                                            name={category.attributes.name}
                                            link={`${NavigationNames.Products}/${category.id}`}
                                            key={category.attributes.url}
                                        >
                                            {category.attributes.subcategories.data.map((subcategory: any) => (
                                                <DropdownItem
                                                    link={`${NavigationNames.Products}/${category.id}/${subcategory.id}`}
                                                    key={subcategory.attributes.url}
                                                >
                                                    {subcategory.attributes.name}
                                                </DropdownItem>
                                            ))}
                                        </DropdownCategory>
                                    );

                                return null;
                            })}
                        </Dropdown>
                    )} */}
                </li>

                {/* <Link
                    to={`${NavigationNames.UsedProducts}`}
                    className={`${s.link} ${pathname.includes(NavigationNames.UsedProducts) ? s.selected : ''}`}
                >
                    {t('usedProducts')}
                </Link> */}

                <Link
                    to={NavigationNames.Service}
                    className={`${s.link} ${pathname.includes(NavigationNames.Service) ? s.selected : ''}`}
                >
                    {t('service')}
                </Link>

                <Link
                    to={NavigationNames.News}
                    className={`${s.link} ${pathname.includes(NavigationNames.News) ? s.selected : ''}`}
                >
                    {t('news')}
                </Link>
                <Link
                    to={NavigationNames.AboutUs}
                    className={`${s.link} ${pathname.includes(NavigationNames.AboutUs) ? s.selected : ''}`}
                >
                    {t('about us')}
                </Link>
                <Link
                    to={NavigationNames.Contact}
                    className={`${s.link} ${pathname.includes(NavigationNames.Contact) ? s.selected : ''}`}
                >
                    {t('contact')}
                </Link>
            </ul>

            <FontAwesomeIcon icon={faBars} className={s.mobileBars} onClick={() => setIsMobileMenuActive(true)} />

            <MobileMenu isActive={isMobileMenuActive} onClose={() => setIsMobileMenuActive(false)} />
        </nav>
    );
};

export default Navbar;
