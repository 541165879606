import React, { useContext, useState } from 'react';
import s from './ProductSubcategory.module.scss';
import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import { Helmet } from 'react-helmet-async';
import backgroundImg from 'assets/subpage-images/products.png';
import { gql, useQuery } from '@apollo/client';
import {
    GET_MANUFACTURERS,
    GET_NEW_PRODUCTS_BY_SUBCATEGORY_ID,
    GET_PRODUCT_SUBCATEGORY,
} from './ProductSubcategory.queries';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from 'contexts/LanguageContext';
import {
    IManufacturersData,
    INewProductsData,
    IProduct,
    IProductSubcategoryData,
    IUsedProduct,
    IUsedProductsData,
} from 'types/strapi/ProductSubcategory';
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';
import { Alert, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import ProductCard from '../ProductSelect/ProductCard/ProductCard';
import { addStrapiUrl } from 'utils/strapiUtils';
import BeanButton from 'components/BeanButton/BeanButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { GetUsedProductsBySubcategoryIdQuery } from 'gql/graphql';

export const GET_USED_PRODUCTS_BY_SUBCATEGORY_ID = gql`
    query GetUsedProductsBySubcategoryId($subcategoryId: ID!, $manufacturerId: ID, $locale: I18NLocaleCode!) {
        usedProducts(
            filters: {
                and: [
                    { subcategory: { id: { eq: $subcategoryId } } }
                    { manufacturer: { id: { eq: $manufacturerId } } }
                ]
            }
            locale: $locale
        ) {
            data {
                id
                attributes {
                    name
                    url
                    is_new
                    is_sold
                    is_reserved
                    images {
                        data {
                            id
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                }
            }
        }
    }
`;
type Props = { usedProducts: boolean };

const ProductSubcategory = ({ usedProducts = false }: Props) => {
    const { language } = useContext(LanguageContext);
    const { t } = useTranslation('ProductSelect');
    const navigate = useNavigate();

    const { subcategoryId } = useParams();
    const [selectedManufacturerId, setSelectedManufacturerId] = useState<string>('unset');

    const { data: manufData, error: manufError } = useQuery<IManufacturersData>(GET_MANUFACTURERS, {
        variables: { subcategoryId },
    });

    const {
        data: subcategoryData,
        loading: subcategoryLoading,
        error: subcategoryError,
    } = useQuery<IProductSubcategoryData>(GET_PRODUCT_SUBCATEGORY, {
        variables: {
            subcategoryId,
            locale: language.localeCode,
        },
    });

    const {
        data: newProductsData,
        loading: newProductsLoading,
        error: newProductsError,
    } = useQuery<INewProductsData>(GET_NEW_PRODUCTS_BY_SUBCATEGORY_ID, {
        variables: {
            subcategoryId,
            manufacturerId: selectedManufacturerId === 'unset' ? undefined : selectedManufacturerId,
            locale: language.localeCode,
        },
        skip: usedProducts,
    });

    const {
        data: usedProductsData,
        loading: usedProductsLoading,
        error: usedProductsError,
    } = useQuery<GetUsedProductsBySubcategoryIdQuery>(GET_USED_PRODUCTS_BY_SUBCATEGORY_ID, {
        variables: {
            subcategoryId,
            manufacturerId: selectedManufacturerId === 'unset' ? undefined : selectedManufacturerId,
            locale: language.localeCode,
        },
        skip: !usedProducts,
    });

    const loading = subcategoryLoading || newProductsLoading || usedProductsLoading;

    const handleReturnClick = (): void => {
        navigate(-1);
    };

    return (
        <>
            <Helmet>
                {subcategoryData && (
                    <>
                        <title>{capitalizeFirstLetter(subcategoryData.productSubcategory.data.attributes.name)}</title>
                        <meta
                            name='description'
                            content={subcategoryData.productSubcategory.data.attributes.description}
                        />
                    </>
                )}
            </Helmet>

            <Layout>
                <SubPage
                    previousPageName={subcategoryData?.productSubcategory.data.attributes.category.data.attributes.name}
                    currentPageName={subcategoryData?.productSubcategory.data.attributes.name}
                    loading={loading}
                    error={subcategoryError || newProductsError || usedProductsError}
                    image={backgroundImg}
                >
                    <div className={s.container}>
                        <div className={s.pageDescription}>
                            <h1 className={s.title}>
                                {usedProducts && t('used')} {subcategoryData?.productSubcategory.data.attributes.name}
                            </h1>
                        </div>

                        {manufError && (
                            <div className={s.filtersContainer}>
                                {/* <p className={s.displayedProductsNumber}>
                                {productCondition === 'new' &&
                                    !productsLoading &&
                                    productsData &&
                                    productsData.products.data &&
                                    (i18n.language === 'de'
                                        ? `Zeige ${productsData.products.data.length} von ${productsData.products.data.length} Produkten`
                                        : `Showing ${productsData.products.data.length} of ${productsData.products.data.length} products`)}

                                {productCondition === 'used' &&
                                    !usedProductsLoading &&
                                    usedProductsData &&
                                    usedProductsData.usedProducts.data &&
                                    (i18n.language === 'de'
                                        ? `Zeige ${usedProductsData.usedProducts.data.length} von ${usedProductsData.usedProducts.data.length} Produkten`
                                        : `Showing ${usedProductsData.usedProducts.data.length} of ${usedProductsData.usedProducts.data.length} products`)}
                            </p> */}

                                <FormControl
                                    disabled={manufData?.manufacturers.data.length === 0}
                                    sx={{ minWidth: 200 }}
                                >
                                    {manufData?.manufacturers.data.length !== 0 && (
                                        <>
                                            <InputLabel id='manufacturer-filter-select-label'>
                                                {t('manufacturer')}
                                            </InputLabel>
                                            <Select
                                                labelId='manufacturer-filter-select-label'
                                                id='manufacturer-filter-select'
                                                label={t('manufacturer')}
                                                value={selectedManufacturerId}
                                                onChange={(event) => setSelectedManufacturerId(event.target.value)}
                                            >
                                                <MenuItem value={'unset'}>{t('all')}</MenuItem>
                                                {manufData?.manufacturers.data.map(({ id, attributes }) => (
                                                    <MenuItem value={id} key={`manufacturer-${id}-${attributes.name}`}>
                                                        {attributes.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </>
                                    )}
                                </FormControl>
                            </div>
                        )}

                        {usedProducts ? (
                            <ul className={s.productsList}>
                                {usedProductsData?.usedProducts?.data &&
                                usedProductsData.usedProducts.data?.length > 0 ? (
                                    <ul className={s.productsList}>
                                        {usedProductsData?.usedProducts?.data?.map((product) => {
                                            const { attributes } = product;

                                            // Check if any necessary property is missing or undefined
                                            if (
                                                !attributes?.name ||
                                                !attributes.images?.data?.[0]?.attributes?.url ||
                                                !product.id
                                            ) {
                                                return null;
                                            }

                                            return (
                                                <ProductCard
                                                    title={attributes.name}
                                                    image={addStrapiUrl(attributes.images.data[0].attributes.url)}
                                                    url={product.id}
                                                    isOfferNew={attributes.is_new ?? false}
                                                    isSold={attributes.is_sold ?? false}
                                                    isReserved={attributes.is_reserved ?? false}
                                                    key={product.id}
                                                />
                                            );
                                        })}
                                    </ul>
                                ) : (
                                    <Alert severity='info' className={s.noProductsFoundText}>
                                        {t('no products')}
                                    </Alert>
                                )}
                            </ul>
                        ) : (
                            <ul className={s.productsList}>
                                {newProductsData && newProductsData.products.data?.length > 0 ? (
                                    newProductsData?.products.data.map(({ attributes, id }: IProduct) => (
                                        <ProductCard
                                            title={attributes.name}
                                            image={addStrapiUrl(attributes.images.data[0].attributes.url)}
                                            url={id.toString()}
                                            isOfferNew={attributes.is_new}
                                            key={id}
                                        />
                                    ))
                                ) : (
                                    <Alert severity='info' className={s.noProductsFoundText}>
                                        {t('no products')}
                                    </Alert>
                                )}
                            </ul>
                        )}

                        <div className={s.returnButtonContainer}>
                            <BeanButton
                                text={t('returnButtonText')}
                                onClick={handleReturnClick}
                                color='blue'
                                variant='outlined'
                                iconStart={<FontAwesomeIcon icon={faAngleLeft} />}
                            />
                        </div>

                        {subcategoryData?.productSubcategory.data.attributes.description && (
                            <div className={s.description}>
                                {subcategoryData?.productSubcategory.data.attributes.description}
                            </div>
                        )}
                    </div>
                </SubPage>
            </Layout>

            {/* <ConditionFab /> */}
        </>
    );
};

export default ProductSubcategory;
