import React, { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface ILanguage {
    flagCode: string;
    localeCode: string;
    name: string;
}

export interface LanguageContextProps {
    language: ILanguage;
    changeLanguage: (lang: ILanguage) => void;
}

export const LANGUAGES: readonly ILanguage[] = [
    { flagCode: 'de', localeCode: 'de', name: 'Deutsch' },
    { flagCode: 'gb', localeCode: 'en', name: 'English' },
];

export const LanguageContext = createContext<LanguageContextProps>({
    language: { flagCode: 'de', localeCode: 'de', name: 'Deutsch' },
    changeLanguage: () => {},
});

const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState<ILanguage>({ flagCode: 'de', localeCode: 'de', name: 'Deutsch' });

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        if (storedLanguage) setLanguage(JSON.parse(storedLanguage));
    }, []);

    const changeLanguage = (selectedLanguage: ILanguage): void => {
        localStorage.setItem('selectedLanguage', JSON.stringify(selectedLanguage));
        setLanguage(selectedLanguage);
        i18n.changeLanguage(selectedLanguage.localeCode);

        navigate('/');
    };
    return <LanguageContext.Provider value={{ language, changeLanguage }}>{children}</LanguageContext.Provider>;
};

export default LanguageProvider;
