import React, { useEffect } from 'react';
import s from './MobileMenu.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { NavigationNames } from '../Navbar';
import { useTranslation } from 'react-i18next';

interface Props {
    isActive: boolean;
    onClose: () => void;
}

const MobileMenu = ({ isActive, onClose }: Props) => {
    const { t } = useTranslation('Navbar');

    useEffect(() => {
        if (isActive) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'visible';
    }, [isActive]);

    return (
        <>
            <div
                className={isActive ? [s.darkBackground, s.active].join(' ') : s.darkBackground}
                onClick={onClose}
            ></div>

            <div className={isActive ? [s.mobileMenu, s.active].join(' ') : s.mobileMenu}>
                <div className={s.mobileMenuTop}>
                    <h3 className={s.mobileMenuTitle}>MENU </h3>
                    <FontAwesomeIcon icon={faTimes} className={s.mobileClose} onClick={onClose} />
                </div>

                <ul className={s.mobileLinksList}>
                    <Link to={NavigationNames.Products} className={s.mobileLink} onClick={onClose}>
                        {t('products')}
                    </Link>
                    <Link to={NavigationNames.UsedProducts} className={s.mobileLink} onClick={onClose}>
                        {t('usedProducts')}
                    </Link>
                    <Link to={NavigationNames.Service} className={s.mobileLink} onClick={onClose}>
                        {t('service')}
                    </Link>
                    <Link to={NavigationNames.News} className={s.mobileLink} onClick={onClose}>
                        {t('news')}
                    </Link>
                    <Link to={NavigationNames.AboutUs} className={s.mobileLink} onClick={onClose}>
                        {t('about us')}
                    </Link>
                    <Link to={NavigationNames.Contact} className={s.mobileLink} onClick={onClose}>
                        {t('contact')}
                    </Link>
                </ul>

                <div className={s.mobileSocials}>
                    <FontAwesomeIcon icon={faFacebook} className={s.socialIcon} />
                    <FontAwesomeIcon icon={faInstagram} className={s.socialIcon} />
                    <FontAwesomeIcon icon={faYoutube} className={s.socialIcon} />
                </div>
            </div>
        </>
    );
};

export default MobileMenu;
