import React, { useState } from 'react';
import s from './ProductInfo.module.scss';
import AskForPriceForm from './AskForPriceForm/AskForPriceForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import DownloadsModal from './DownloadsModal/DownloadsModal';
import BeanButton from 'components/BeanButton/BeanButton';
import { useTranslation } from 'react-i18next';
import { GetProductByIdQuery, GetUsedProductByIdQuery } from 'gql/graphql';
import ProductBadge from 'components/ProductBadge/ProductBadge';

interface Props {
    categoryName: string;
    categoryUrl: string;
    name: string;
    description: string;
    downloadFiles:
        | NonNullable<NonNullable<NonNullable<GetProductByIdQuery['product']>['data']>['attributes']>['download_files']
        | NonNullable<
              NonNullable<NonNullable<GetUsedProductByIdQuery['usedProduct']>['data']>['attributes']
          >['download_files'];
    isUsed?: boolean;
    priceNetto?: number | null;
    priceBrutto?: number | null;
    priceCurrency?: string;
    isSold?: boolean;
    isReserved?: boolean;
}

const ProductInfo = ({
    categoryName,
    categoryUrl,
    name,
    description,
    downloadFiles,
    priceNetto,
    priceBrutto,
    priceCurrency,
    isUsed = false,
    isSold,
    isReserved,
}: Props) => {
    const { t } = useTranslation('Product');

    const [isAskForPriceFormOpen, setAskForPriceIsFormOpen] = useState(false);

    const [areDownloadsOpen, setAreDownloadOpen] = useState(false);

    const handleAskForPriceFormOpen = (): void => setAskForPriceIsFormOpen(true);

    const handleAskForPriceFormClose = (): void => setAskForPriceIsFormOpen(false);

    const handleDownloadFilesButton = (): void => {
        setAreDownloadOpen(true);
    };

    return (
        <>
            <div className={s.productInfo}>
                <div className={s.productInfoText}>
                    {/* <Link
                        to={`${isUsed ? NavigationNames.UsedProducts : NavigationNames.Products}/${categoryUrl}`}
                        className={s.productCategory}
                    >
                        {categoryName}
                    </Link> */}
                    <span>{categoryName}</span>

                    <div className={s.badgesContainer}>
                        {isSold && <ProductBadge type='sold' />}
                        {isReserved && <ProductBadge type='reserved' />}
                    </div>

                    <h1 className={s.productName}>{name}</h1>
                    <p className={s.productDescription}>{description}</p>
                </div>

                {(priceNetto || priceBrutto) && (
                    <div className={s.pricesContainer}>
                        {priceNetto && (
                            <p className={s.price}>
                                {t('net')}: {priceNetto.toLocaleString('de-DE')} {priceCurrency}
                            </p>
                        )}

                        {priceBrutto && (
                            <p className={s.price}>
                                {t('gross')}:{' '}
                                <span className={s.priceGross}>
                                    {priceBrutto.toLocaleString('de-DE')} {priceCurrency}
                                </span>
                            </p>
                        )}
                    </div>
                )}

                <div className={s.buttons}>
                    <BeanButton
                        text={t('toDownload')}
                        fullWidth
                        disabled={downloadFiles?.data.length === 0}
                        color='black'
                        iconEnd={<FontAwesomeIcon icon={faDownload} size='lg' />}
                        onClick={handleDownloadFilesButton}
                    />

                    {/* {!isUsed && (
                        <div className={s.mainButton}>
                            <BeanButton
                                text={t('askForPrice')}
                                fullWidth
                                color='blue'
                                iconEnd={<FontAwesomeIcon icon={faEnvelope} size='lg' />}
                                onClick={handleAskForPriceFormOpen}
                                disabled
                            />
                        </div>
                    )} */}
                </div>

                {isUsed && (
                    <div className={s.pricesContainer}>
                        <p className={s.price}>
                            {t('net')}: {priceNetto} {priceCurrency}
                        </p>
                        <p className={s.price}>
                            {t('gross')}:{' '}
                            <span className={s.priceGross}>
                                {priceBrutto} {priceCurrency}
                            </span>
                        </p>
                    </div>
                )}
            </div>

            <AskForPriceForm isOpen={isAskForPriceFormOpen} onClose={handleAskForPriceFormClose} productName={name} />
            <DownloadsModal
                isOpen={areDownloadsOpen}
                onClose={() => setAreDownloadOpen(false)}
                downloadFiles={downloadFiles}
            />
        </>
    );
};

export default ProductInfo;
