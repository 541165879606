import React, { useContext } from 'react';
import s from './Footer.module.scss';
import { Link } from 'react-router-dom';
import SocialMediaLinks from 'components/SocialMediaLinks/SocialMediaLinks';
import { NavigationNames } from '../Navbar/Navbar';
import { useTranslation } from 'react-i18next';
import { GET_CATEGORIES } from '../Navbar/Navbar.query';
import { useQuery } from '@apollo/client';
import { LanguageContext } from 'contexts/LanguageContext';

interface Props {}

const Footer = (props: Props) => {
    const { language } = useContext(LanguageContext);

    const { t } = useTranslation('Footer');

    const {
        loading: categoriesLoading,
        error: categoriesError,
        data: categoriesData,
    } = useQuery(GET_CATEGORIES, {
        variables: { locale: language.localeCode },
    });

    return (
        <footer className={s.footer}>
            <div className={s.footerTop}>
                <div className={s.linksContainer}>
                    <h4 className={s.linksTitle}>Optic Handel Fragstein</h4>
                    <ul className={s.linkList}>
                        <li className={s.linkListItem}>
                            <Link to={NavigationNames.Products} className={s.linkAnchor}>
                                {t('OpticHandel.products')}
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Link to={NavigationNames.UsedProducts} className={s.linkAnchor}>
                                {t('OpticHandel.usedProducts')}
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Link to={NavigationNames.Service} className={s.linkAnchor}>
                                {t('OpticHandel.Service')}
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Link to={NavigationNames.AboutUs} className={s.linkAnchor}>
                                {t('OpticHandel.AboutUs')}
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Link to={NavigationNames.Contact} className={s.linkAnchor}>
                                {t('OpticHandel.Contact')}
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className={s.linksContainer}>
                    <h4 className={s.linksTitle}>{t('Information.Information')}</h4>
                    <ul className={s.linkList}>
                        <li className={s.linkListItem}>
                            <Link to={NavigationNames.Financing} className={s.linkAnchor}>
                                {t('Information.Financing')}
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Link to={NavigationNames.LegalNotice} className={s.linkAnchor}>
                                {t('Information.LegalNotices')}
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Link to={NavigationNames.TermsOfSale} className={s.linkAnchor}>
                                {t('Information.GeneralSalesConditions')}
                            </Link>
                        </li>
                        <li className={s.linkListItem}>
                            <Link to={NavigationNames.Rodo} className={s.linkAnchor}>
                                {t('Information.GDPRInformationObligation')}
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className={s.linksContainer}>
                    <h4 className={s.linksTitle}>{t('Products.Products')}</h4>
                    <ul className={s.linkList}>
                        {categoriesData?.productCategories.data.map((category: any) => (
                            <li className={s.linkListItem} key={category.id}>
                                <Link to={`${NavigationNames.Products}/${category.id}`} className={s.linkAnchor}>
                                    {category.attributes.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={s.newsletter}>
                    <SocialMediaLinks />
                </div>
            </div>

            <div className={s.copyright}>
                <span className={s.copyrightText}>
                    Copyright © {new Date().getFullYear()} Optic Handel All Rights Reserved
                </span>
            </div>
        </footer>
    );
};

export default Footer;
