import React from 'react';
import s from './NotFoundPage.module.scss';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import BeanButton from 'components/BeanButton/BeanButton';
import Layout from 'components/Layout/Layout';
import { useTranslation } from 'react-i18next';

interface Props {
    showLayout?: boolean;
}

const NotFound = ({ showLayout = true }: Props) => {
    const { t } = useTranslation('NotFoundPage');
    const navigate = useNavigate();

    const handleHomeClick = (): void => {
        navigate('/');
    };

    const handleReturnClick = (): void => {
        navigate(-1);
    };

    if (showLayout)
        return (
            <Layout>
                <div className={s.container}>
                    <div className={s.content}>
                        <h1 className={s.title}>{t('pageNotFound')}</h1>
                        <p>{t('info')}</p>
                        <BeanButton
                            text={t('homePage').toString()}
                            onClick={handleHomeClick}
                            color='blue'
                            iconStart={<FontAwesomeIcon icon={faHome} />}
                        />
                        <BeanButton
                            text={t('return').toString()}
                            onClick={handleReturnClick}
                            color='blue'
                            iconStart={<FontAwesomeIcon icon={faAngleLeft} />}
                        />
                    </div>
                </div>
            </Layout>
        );

    return (
        <div className={s.container}>
            <div className={s.content}>
                <h1 className={s.title}>{t('pageNotFound')}</h1>
                <p>{t('info')}</p>
                <BeanButton
                    text={t('homePage').toString()}
                    onClick={handleHomeClick}
                    color='blue'
                    iconStart={<FontAwesomeIcon icon={faHome} />}
                />
                <BeanButton
                    text={t('return').toString()}
                    onClick={handleReturnClick}
                    color='blue'
                    iconStart={<FontAwesomeIcon icon={faAngleLeft} />}
                />
            </div>
        </div>
    );
};

export default NotFound;
