import React from 'react';
import s from './ContactSection.module.scss';
import BeanButton from 'components/BeanButton/BeanButton';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import { useTranslation } from 'react-i18next';

type Props = {};

const ContactSection = (props: Props) => {
    const { t } = useTranslation('HomePage');

    return (
        <section className={s.contactSection}>
            <h2 className={s.title}>{t('contact-section-title')}</h2>
            <BeanButton
                text={t('contact-section-button-text')}
                link={NavigationNames.Contact}
                color='white'
                variant='outlined'
            />
        </section>
    );
};

export default ContactSection;
