import React, { useState } from 'react';
import s from './ServicePage.module.scss';
import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import ServiceRequestForm from './ServiceRequestForm/ServiceRequestForm';
import ServiceArticlesList from './ServiceArticlesList/ServiceArticlesList';
import { gql, useQuery } from '@apollo/client';
import { IServiceArticle } from 'types/types';
import Loading from 'components/Loading/Loading';
import { Route, Routes } from 'react-router-dom';
import Article from './Article/Article';
import NotFound from 'pages/NotFoundPage/NotFoundPage';
import backgroundImage from 'assets/subpage-images/service.png';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import BeanButton from 'components/BeanButton/BeanButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';

interface Props {}

const GET_SERVICE_ARTICLES = gql`
    query GetServicePageArticles {
        serviceArticles(pagination: { limit: 10 }, sort: "createdAt:desc") {
            data {
                id
                attributes {
                    title
                    description
                    createdAt
                    image {
                        data {
                            id
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                }
            }
        }
    }
`;

const ServicePage = (props: Props) => {
    const { t, i18n } = useTranslation('ServicePage');
    const [isServiceRequestOpen, setIsServiceRequestOpen] = useState(false);

    const { loading: loadingArticles, error: errorArticles, data: dataArticles } = useQuery(GET_SERVICE_ARTICLES);
    const articles: IServiceArticle[] = dataArticles?.serviceArticles?.data;

    return (
        <>
            <Helmet>
                <title>Service</title>
            </Helmet>
            <Layout>
                <Routes>
                    <Route
                        index
                        element={
                            <SubPage
                                title={t('pageTitle').toString()}
                                image={backgroundImage}
                                actions={
                                    <div className={s.buttonsContainer}>
                                        {/* <BeanButton
                                            color='black'
                                            text={t('serviceButtonText')}
                                            onClick={() => setIsServiceRequestOpen(true)}
                                            iconEnd={<FontAwesomeIcon icon={faWrench} size='lg' />}
                                        /> */}
                                    </div>
                                }
                            >
                                {loadingArticles && <Loading />}

                                {!loadingArticles && (
                                    <div className={s.container}>
                                        <div className={s.mainContent}>
                                            <div className={s.heading}>Service</div>
                                            <div className={s.textContent}>
                                                {i18n.language === 'en' ? (
                                                    <>
                                                        <h2 className={s.subtitle}>Delivery & Assembly</h2>

                                                        <p className={s.secondaryText}>
                                                            Our experienced Service-Team delivers and assembles
                                                            refraction devices, such as Examination Units, Slit Lamps
                                                            and other instruments, as well as workshop machines like
                                                            Lens Edgers and Hand Edgers.
                                                        </p>

                                                        <h2 className={s.subtitle}>Repair & Maintenance</h2>

                                                        <p className={s.secondaryText}>
                                                            Repairs are carried out by our own personnel, either at the
                                                            Customer’s premises or in our own workshop. Our experienced
                                                            staff is available to our customers for all questions with
                                                            regards to their equipment.
                                                        </p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <p className={s.secondaryText}>
                                                            Refraktionsgeräte wie z.B. Untersuchungs-Einheiten,
                                                            Spaltlampen, Instrumententräger und auch Werkstatt-Geräte
                                                            wie z.B. Schleifautomaten, Handschleifsteine u.ä. werden von
                                                            unserem erfahrenen Service-Team bei Ihnen angeliefert und
                                                            montiert.
                                                        </p>

                                                        <h2 className={s.subtitle}>Reparatur- und Wartungsservice</h2>

                                                        <p className={s.secondaryText}>
                                                            Reparaturen werden durch eigenes Personal durchgeführt,
                                                            entweder vor Ort beim Kunden oder in der eigenen Werkstatt.
                                                            Das Unternehmen beschäftigt erfahrene Mitarbeiter, die den
                                                            Kunden für sämtliche Fragen zur Verfügung stehen.
                                                        </p>

                                                        <h2 className={s.subtitle}>Über Uns</h2>

                                                        <p className={s.secondaryText}>
                                                            Seit 1995 beliefert die Firma Optic-Handel Fragstein mit
                                                            Sitz in Würselen (bei Aachen) Augenoptiker und Augenärzte
                                                            mit ophthalmologischen und optischen Geräten. Durch unser
                                                            großes Lager können unsere Kunden auf ein riesiges Sortiment
                                                            an Neu- und Gebrauchtgeräten zugreifen.
                                                        </p>

                                                        <p className={s.secondaryText}>
                                                            Das Lieferprogramm umfasst Kleingeräte, genauso wie
                                                            ophthalmologische Großgeräte und Refraktionseinheiten. Durch
                                                            unsere enge Zusammenarbeit mit den führenden Herstellern von
                                                            ophthalmologischen und optischen Geräten können wir fast
                                                            alle auf dem Markt befindlichen Geräte anbieten. Zu unseren
                                                            Partnern zählen unter anderem Rodenstock Instruments,
                                                            i-Tronics, Oculus/Nidek, HS Doms, Topcon Medical, iCare,
                                                            Tomey, Moptim und Heine. Auch für den Umzug Ihrer
                                                            ophthalmologischen und optischen Geräte sind wir der
                                                            richtige Ansprechpartner.
                                                        </p>

                                                        <p className={s.secondaryText}>
                                                            Anfang August 2011 haben wir den Alleinvertrieb für
                                                            Rodenstock Instrumente in Deutschland übernommen und können
                                                            mittlerweile auf eine mehr als 10-jährige erfolgreiche
                                                            Zusammenarbeit zurückblicken. Mit 4 Standorten in Europa
                                                            sind wir auch international gut aufgestellt. Qualitativ
                                                            hochwertige Refraktionseinheiten und Instrumententische aus
                                                            eigener Produktion in Polen runden unsere Produktpalette ab.
                                                            Unsere spezialisierten Techniker führen die Wartung und
                                                            Reparaturen von Kundengeräten aller Art in der eigenen
                                                            Werkstatt durch.
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {articles && <ServiceArticlesList articles={articles} showBlogButton />}
                                    </div>
                                )}

                                <ServiceRequestForm
                                    isOpen={isServiceRequestOpen}
                                    onClose={() => setIsServiceRequestOpen(false)}
                                />
                            </SubPage>
                        }
                    />
                    <Route path=':id' element={<Article />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Layout>
        </>
    );
};

export default ServicePage;
