import React, { useState } from 'react';
import s from './AskForPriceForm.module.scss';
import Modal from '@mui/material/Modal';
import { Alert, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    productName: string;
};

const URL = `${process.env.REACT_APP_STRAPI_URL}/product/ask-for-price`;

const AskForPriceForm = ({ isOpen, onClose, productName }: Props) => {
    const { t } = useTranslation('AskForPriceForm');
    const [name, setName] = useState<string>('');
    const [surname, setSurname] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [details, setDetails] = useState<string>('');
    const [alertMessage, setAlertMessage] = useState<string>(t('alertSuccessMessage') as string);
    const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>('success');
    const [isAlertActive, setIsAlertActive] = useState<boolean>(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const body = {
            name,
            surname,
            phone,
            email,
            company,
            details,
            productName,
            productLink: window.location.href,
        };

        try {
            onClose();
            const response = await fetch(URL, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            if (response.status === 200) {
                setAlertMessage(t('alertSuccessMessage') as string);
                setAlertSeverity('success');
            } else {
                setAlertMessage(t('alertErrorMessage') as string);
                setAlertSeverity('error');
            }
        } catch (error) {
            console.error(error);
            setAlertMessage(t('alertErrorMessage') as string);
            setAlertSeverity('error');
        } finally {
            setIsAlertActive(true);
            setTimeout(() => setIsAlertActive(false), 5000);
            resetForm();
        }
    };

    const handleFormClose = () => {
        onClose();
        resetForm();
    };

    const resetForm = () => {
        setName('');
        setSurname('');
        setPhone('');
        setEmail('');
        setCompany('');
        setDetails('');
    };

    return (
        <>
            <Modal className={s.modal} open={isOpen} onClose={handleFormClose}>
                <div className={s.formContainer}>
                    <h5 className={s.title}>{t('modalTitle')}</h5>

                    <Box component='form' className={s.form} onSubmit={handleSubmit}>
                        <TextField
                            id='name'
                            label={t('firstNameLabel')}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />

                        <TextField
                            id='surname'
                            label={t('lastNameLabel')}
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                            required
                        />

                        <TextField
                            id='email'
                            label={t('emailLabel')}
                            type={'email'}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />

                        <TextField
                            id='phone'
                            label={t('phoneLabel')}
                            inputProps={{ inputMode: 'tel', pattern: '\\d{9,}' }}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />

                        <TextField
                            id='company'
                            label={t('companyNameLabel')}
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            className={s.detailsInput}
                            required
                        />

                        <TextField
                            id='details'
                            className={s.detailsInput}
                            multiline
                            minRows={3}
                            maxRows={6}
                            label={t('detailsLabel')}
                            value={details}
                            onChange={(e) => setDetails(e.target.value)}
                        />

                        <FormControlLabel
                            className={s.checkboxLabel}
                            control={<Checkbox required />}
                            label={t('consentCheckboxLabel')}
                        />

                        <Button className={s.submitButton} type={'submit'} variant='contained'>
                            {t('submitButton')}
                        </Button>
                        <Button
                            className={s.submitButton}
                            color='secondary'
                            variant='outlined'
                            onClick={handleFormClose}
                        >
                            {t('cancelButton')}
                        </Button>
                    </Box>
                </div>
            </Modal>

            {isAlertActive && (
                <div className={s.alert}>
                    <Alert variant='filled' severity={alertSeverity} onClose={() => setIsAlertActive(false)}>
                        {alertMessage}
                    </Alert>
                </div>
            )}
        </>
    );
};

export default AskForPriceForm;
