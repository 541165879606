import SubPage from 'components/SubPage/SubPage';
import React, { useContext } from 'react';
import s from './ProductCategory.module.scss';
import { Helmet } from 'react-helmet-async';
import backgroundImg from 'assets/subpage-images/products.png';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_PRODUCT_CATEGORY, GET_SUBCATEGORIES } from './ProductCategory.queries';
import { LanguageContext } from 'contexts/LanguageContext';
import { IProductCategory } from 'types/strapi/ProductCategory';
import Layout from 'components/Layout/Layout';
import CategoryCard from '../CategorySelect/CategoryCard/CategoryCard';
import ConditionFab from 'components/ConditionFab/ConditionFab';
import { useTranslation } from 'react-i18next';
import { StrapiImageData } from 'types/strapi/Shared';

type Props = {
    usedProducts: boolean;
};

interface IProductCategoryResponse {
    productCategory: {
        data: IProductCategory;
    };
}

interface ISubcategoriesResponse {
    productSubcategories: {
        data: {
            id: number;
            attributes: {
                name: string;
                url: string;
                description: string;
                image: StrapiImageData;
                used_products: {
                    data: {
                        id: number;
                    }[];
                };
                products: {
                    data: {
                        id: number;
                    }[];
                };
            };
        }[];
    };
}

const ProductCategory = ({ usedProducts }: Props) => {
    const { categoryId } = useParams();
    const { language } = useContext(LanguageContext);
    const { t } = useTranslation('ProductCategory');

    const categoryQuery = useQuery<IProductCategoryResponse>(GET_PRODUCT_CATEGORY, {
        variables: { categoryId, locale: language.localeCode },
    });

    const subcategoriesQuery = useQuery<ISubcategoriesResponse>(GET_SUBCATEGORIES, {
        variables: {
            categoryId,
            locale: language.localeCode,
        },
    });

    const { name, description } = categoryQuery.data?.productCategory.data.attributes || {};

    return (
        <>
            <Helmet>
                <title>{name?.[0]?.toUpperCase() + (name ? name.slice(1).toLowerCase() : '')}</title>
                <meta name='description' content={description} />
            </Helmet>

            <Layout>
                <SubPage
                    error={categoryQuery.error || subcategoriesQuery.error}
                    currentPageName={name}
                    loading={categoryQuery.loading || subcategoriesQuery.loading}
                    title={usedProducts ? `${t('used')} ${name}` : name}
                    image={backgroundImg}
                >
                    <ul className={s.categoriesList}>
                        {subcategoriesQuery.data?.productSubcategories.data.map(({ attributes, id }) => {
                            const { name, image, products, used_products } = attributes;

                            return (
                                <CategoryCard
                                    image={image}
                                    category={name}
                                    categoryId={id}
                                    key={id}
                                    emptyCategory={(usedProducts ? used_products.data : products.data).length === 0}
                                />
                            );
                        })}
                    </ul>
                </SubPage>
            </Layout>

            {/* <ConditionFab /> */}
        </>
    );
};

export default ProductCategory;
