import React from 'react';
import s from './NewUsedCategoryCard.module.scss';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { ButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';

interface Props {
    id: number;
    category: string;
    image: string;
    url: string;
    description?: string;
}

const NewUsedCategoryCard = ({ id, image, category, url, description }: Props) => {
    const { t } = useTranslation('CategorySelect');

    return (
        <li className={s.card}>
            <div className={s.imageContainer}>
                <img className={s.image} src={image} alt='category' />
            </div>
            <div className={s.content}>
                <h3 className={s.title}>{category}</h3>
                {description && <span className={s.description}>{description}</span>}
            </div>
            <ButtonGroup fullWidth variant='contained' className={s.buttonsContainer}>
                <Button component={Link} to={`../${NavigationNames.Products}/${id.toString()}`}>
                    {t('new')}
                </Button>
                <Button component={Link} to={`../${NavigationNames.UsedProducts}/${id.toString()}`}>
                    {t('used')}
                </Button>
            </ButtonGroup>
        </li>
    );
};

export default NewUsedCategoryCard;
