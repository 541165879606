import { gql } from '@apollo/client';

// export const GET_SUBCATEGORIES = gql`
//     query GetProductCategories($categoryId: ID!, $locale: I18NLocaleCode!) {
//         productSubcategories(
//             pagination: { limit: -1 }
//             filters: { category: { id: { eq: $categoryId } } }
//             locale: $locale
//         ) {
//             data {
//                 id
//                 attributes {
//                     name
//                     url
//                     description
//                     image {
//                         data {
//                             attributes {
//                                 url
//                                 alternativeText
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `;

export const GET_PRODUCT_CATEGORY = gql`
    query GetProductCategory($categoryId: ID!, $locale: I18NLocaleCode!) {
        productCategory(id: $categoryId, locale: $locale) {
            data {
                id
                attributes {
                    name
                    url
                    description
                    image {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    subcategories {
                        data {
                            id
                            attributes {
                                name
                                image {
                                    data {
                                        attributes {
                                            url
                                            alternativeText
                                        }
                                    }
                                }
                                url
                                used_products {
                                    data {
                                        id
                                    }
                                }
                                products {
                                    data {
                                        id
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_SUBCATEGORIES = gql`
    query GetProductCategories($categoryId: ID!, $locale: I18NLocaleCode!) {
        productSubcategories(
            pagination: { limit: -1 }
            filters: { category: { id: { eq: $categoryId } } }
            locale: $locale
        ) {
            data {
                id
                attributes {
                    name
                    url
                    description
                    image {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    used_products {
                        data {
                            id
                        }
                    }
                    products {
                        data {
                            id
                        }
                    }
                }
            }
        }
    }
`;
