import React, { useContext } from 'react';
import s from './Product.module.scss';
import SubPage from 'components/SubPage/SubPage';
import ProductMedia from './ProductMedia/ProductMedia';
import ProductInfo from './ProductInfo/ProductInfo';
import ProductSidebar from './ProductSidebar/ProductSidebar';
import ProductMainDescription from './ProductMainDescription/ProductMainDescription';
import { gql, useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import { LanguageContext } from 'contexts/LanguageContext';
import { useParams } from 'react-router-dom';
import Layout from 'components/Layout/Layout';
import { GetProductByIdQuery, GetUsedProductByIdQuery } from 'gql/graphql';

interface Props {
    isUsed?: boolean;
}

export const GetProductById = gql`
    query GetProductById($id: ID!, $locale: I18NLocaleCode!) {
        product(id: $id, locale: $locale) {
            data {
                id
                attributes {
                    name
                    url
                    short_description
                    description
                    is_new
                    price_net
                    price_gross
                    price_currency
                    images {
                        data {
                            id
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    subcategory {
                        data {
                            attributes {
                                url
                                name
                                category {
                                    data {
                                        attributes {
                                            url
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                    accessories {
                        data {
                            id
                            attributes {
                                name
                                url
                                images {
                                    data {
                                        id
                                        attributes {
                                            url
                                            alternativeText
                                        }
                                    }
                                }
                                subcategory {
                                    data {
                                        id
                                        attributes {
                                            url
                                            category {
                                                data {
                                                    id
                                                    attributes {
                                                        url
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    related_products {
                        data {
                            id
                            attributes {
                                url
                                name
                                images {
                                    data {
                                        attributes {
                                            url
                                            alternativeText
                                        }
                                    }
                                }
                                subcategory {
                                    data {
                                        id
                                        attributes {
                                            url
                                            category {
                                                data {
                                                    id
                                                    attributes {
                                                        url
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    manufacturer {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    videos {
                        data {
                            id
                            attributes {
                                previewUrl
                                url
                                alternativeText
                                caption
                            }
                        }
                    }
                    download_files {
                        data {
                            id
                            attributes {
                                url
                                name
                                caption
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GetUsedProductById = gql`
    query GetUsedProductById($id: ID!) {
        usedProduct(id: $id) {
            data {
                id
                attributes {
                    name
                    url
                    short_description
                    description
                    is_new
                    is_sold
                    is_reserved
                    images {
                        data {
                            id
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    subcategory {
                        data {
                            attributes {
                                url
                                name
                                category {
                                    data {
                                        attributes {
                                            url
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                    accessories {
                        data {
                            id
                            attributes {
                                name
                                url
                                images {
                                    data {
                                        attributes {
                                            url
                                            alternativeText
                                        }
                                    }
                                }
                                subcategory {
                                    data {
                                        attributes {
                                            url
                                            category {
                                                data {
                                                    attributes {
                                                        url
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    related_products {
                        data {
                            id
                            attributes {
                                url
                                name
                                images {
                                    data {
                                        attributes {
                                            url
                                            alternativeText
                                        }
                                    }
                                }
                                subcategory {
                                    data {
                                        attributes {
                                            url
                                            category {
                                                data {
                                                    attributes {
                                                        url
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    manufacturer {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                    videos {
                        data {
                            id
                            attributes {
                                previewUrl
                                url
                                alternativeText
                                caption
                            }
                        }
                    }
                    download_files {
                        data {
                            id
                            attributes {
                                url
                                name
                                caption
                            }
                        }
                    }
                    price_net
                    price_gross
                    price_currency
                }
            }
        }
    }
`;

const Product = ({ isUsed = false }: Props) => {
    const { language } = useContext(LanguageContext);
    const { productId } = useParams();

    const {
        loading: newProductLoading,
        data: newProductData,
        error: newProductError,
    } = useQuery<GetProductByIdQuery>(GetProductById, {
        variables: { id: productId, locale: language.localeCode },
        skip: isUsed,
    });

    const {
        loading: usedProductLoading,
        data: usedProductData,
        error: usedProductError,
    } = useQuery<GetUsedProductByIdQuery>(GetUsedProductById, {
        variables: { id: productId, locale: language.localeCode },
        skip: !isUsed,
    });

    const queryData = isUsed ? usedProductData?.usedProduct : newProductData?.product;

    const hasSidebar: boolean =
        queryData?.data?.attributes?.accessories?.data?.length !== 0 ||
        queryData?.data?.attributes?.related_products?.data?.length !== 0;

    return (
        <>
            <Helmet>
                <title>{newProductData?.product?.data?.attributes?.name ?? 'Product'}</title>
                <meta name='description' content={newProductData?.product?.data?.attributes?.short_description ?? ''} />
            </Helmet>

            <Layout>
                <SubPage
                    error={newProductError || usedProductError}
                    loading={newProductLoading || usedProductLoading}
                    currentPageName={queryData?.data?.attributes?.name ?? 'Product'}
                    previousPageName={queryData?.data?.attributes?.subcategory?.data?.attributes?.name ?? 'Category'}
                >
                    {queryData && (
                        <div className={[s.container, hasSidebar ? '' : s.noSidebar].join(' ')}>
                            {hasSidebar && (
                                <ProductSidebar
                                    accessories={queryData.data?.attributes?.accessories}
                                    relatedProducts={queryData?.data?.attributes?.related_products}
                                />
                            )}

                            <ProductMedia
                                images={queryData.data?.attributes?.images ?? { data: [] }}
                                videos={queryData.data?.attributes?.videos}
                            />

                            <ProductInfo
                                categoryName={
                                    queryData?.data?.attributes?.subcategory?.data?.attributes?.category?.data
                                        ?.attributes?.name ?? ''
                                }
                                categoryUrl={
                                    queryData?.data?.attributes?.subcategory?.data?.attributes?.category?.data
                                        ?.attributes?.url ?? ''
                                }
                                priceCurrency={queryData?.data?.attributes?.price_currency ?? ''}
                                priceNetto={queryData?.data?.attributes?.price_net}
                                priceBrutto={queryData?.data?.attributes?.price_gross}
                                name={queryData?.data?.attributes?.name ?? ''}
                                description={queryData?.data?.attributes?.short_description ?? ''}
                                downloadFiles={queryData?.data?.attributes?.download_files}
                                isSold={usedProductData?.usedProduct?.data?.attributes?.is_sold ?? false}
                                isReserved={usedProductData?.usedProduct?.data?.attributes?.is_reserved ?? false}
                            />

                            <ProductMainDescription
                                skipSidebar={!hasSidebar}
                                description={queryData?.data?.attributes?.description ?? ''}
                            />
                        </div>
                    )}
                </SubPage>
            </Layout>
        </>
    );
};

export default Product;
