import React from 'react';
import s from './ProductCard.module.scss';
import { Link } from 'react-router-dom';
import ProductBadge from 'components/ProductBadge/ProductBadge';

interface Props {
    title: string;
    description?: string;
    url: string;
    image: string;
    isOfferNew?: boolean;
    isSold?: boolean;
    isReserved?: boolean;
}

const ProductCard = ({
    title,
    description,
    url,
    image,
    isOfferNew = false,
    isSold = false,
    isReserved = false,
}: Props) => {
    return (
        <li className={s.cardContainer}>
            <Link to={url} className={s.link}>
                <div className={s.imageContainer}>
                    <img className={s.image} src={image} alt='category' />
                </div>
                <div className={s.badgesContainer}>
                    {isOfferNew && <ProductBadge type='new' />}
                    {isSold && <ProductBadge type='sold' />}
                    {isReserved && <ProductBadge type='reserved' />}
                </div>

                <div className={s.content}>
                    <h3 className={s.title}>{title}</h3>
                    {description && <span className={s.description}>{description}</span>}
                </div>
            </Link>
        </li>
    );
};

export default ProductCard;
