import React, { ReactNode } from 'react';
import s from './SubPage.module.scss';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Loading from 'components/Loading/Loading';
import { Alert, AlertTitle } from '@mui/material';
import { ApolloError } from '@apollo/client';

type Props = {
    children: ReactNode;
    currentPageName?: string;
    showBreadcrumbs?: boolean;
    title?: string;
    description?: string;
    actions?: ReactNode;
    fullWidth?: boolean;
    image?: string;
    loading?: boolean;
    error?: ApolloError;
    previousPageName?: string;
};

const SubPage = ({
    children,
    title,
    description,
    actions,
    image,
    fullWidth = false,
    currentPageName,
    showBreadcrumbs = true,
    loading,
    error,
    previousPageName,
}: Props) => {
    const imageStyle = {
        backgroundImage: image && `url("${image}")`,
    };

    const noImageStyle = {
        background: 'linear-gradient(to right, #0072bc, #3f9add, #80c4ff)',
    };

    const style = image ? imageStyle : noImageStyle;

    return (
        <div className={s.container}>
            {title && (
                <div className={s.headerBgImage} style={style}>
                    <div className={s.header}>
                        <div className={s.headerContent}>
                            <div className={s.textContainer}>
                                <h1 className={s.title}>{title}</h1>
                                {description && <p className={s.description}>{description}</p>}
                            </div>
                            {actions}
                        </div>
                    </div>
                </div>
            )}
            <div className={`${s.content} ${fullWidth && s.fullWidth}`}>
                {/* {showBreadcrumbs && (
                    <Breadcrumbs previousPageName={previousPageName} currentPageName={currentPageName} />
                )} */}

                {error ? (
                    <Alert severity='error'>
                        <AlertTitle>Error has occured.</AlertTitle> Error Message: {error.message}
                    </Alert>
                ) : (
                    <>
                        {loading ? (
                            <Loading />
                        ) : (
                            <>
                                {showBreadcrumbs && (
                                    <>
                                        <Breadcrumbs
                                            previousPageName={previousPageName}
                                            currentPageName={currentPageName}
                                        />
                                        {children}
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default SubPage;
