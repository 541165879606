import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';
import Event from './Event/Event';
import EventsList from './EventsList/EventsList';
import { useTranslation } from 'react-i18next';

type Props = {};

const EventsPage = (props: Props) => {
    const { t } = useTranslation('EventsPage');

    return (
        <>
            <Helmet>
                <title>{t('pageTitle')}</title>
            </Helmet>
            <Layout>
                <Routes>
                    <Route
                        index
                        element={
                            <SubPage title={t('pageTitle').toString()} currentPageName={t('pageTitle').toString()}>
                                <EventsList />
                            </SubPage>
                        }
                    />
                    <Route path=':id' element={<Event />} />
                </Routes>
            </Layout>
        </>
    );
};

export default EventsPage;
