import React, { useContext } from 'react';
import s from './ContactPage.module.scss';
import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import PersonnelListItem from './PersonnelListItem/PersonnelListItem';
import PersonnelList from './PersonnelList/PersonnelList';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { LanguageContext } from 'contexts/LanguageContext';
import DOMPurify, { sanitize } from 'dompurify';

type Props = {};

interface IContactPageInfoData {
    contactPage: {
        data: IContactPageInfo;
    };
}

interface IContactPageInfo {
    id: number;
    attributes: {
        address_label: string;
        address: string;
        phone: string;
        phone_label: string;
        email_label: string;
        email: string;
        opening_hours_label: string;
        opening_hours_info: string;
    };
}

interface IContactpageEmployeesData {
    contactPageEmployees: {
        data: IContactpageEmployee[];
    };
}

interface IContactpageEmployee {
    id: number;
    attributes: {
        full_name: string;
        info: string;
        Role: EmployeeRolesEnum;
    };
}

const GET_CONTACT_INFO = gql`
    query getContactInfo($locale: I18NLocaleCode!) {
        contactPage(locale: $locale) {
            data {
                attributes {
                    address_label
                    address
                    phone
                    phone_label
                    email_label
                    email
                    opening_hours_label
                    opening_hours_info
                }
            }
        }
    }
`;

const GET_EMPLOYEES = gql`
    query getEmployees {
        contactPageEmployees(locale: "de") {
            data {
                id
                attributes {
                    full_name
                    info
                    Role
                }
            }
        }
    }
`;

enum EmployeeRolesEnum {
    Sales = 'sales',
    TechnicianAndService = 'technician_and_service',
}

const Contact = (props: Props) => {
    const { t } = useTranslation('ContactPage');
    // const { language } = useContext(LanguageContext);

    const {
        data: infoData,
        loading: infoLoading,
        error: infoError,
    } = useQuery<IContactPageInfoData>(GET_CONTACT_INFO, {
        variables: { locale: 'de' },
    });

    const {
        data: employeesData,
        loading: employeesLoading,
        error: employeesError,
    } = useQuery<IContactpageEmployeesData>(GET_EMPLOYEES);

    const loading = infoLoading || employeesLoading;
    // const errors = [infoError, employeesError].filter((error) => error !== undefined);
    const contactInfo = infoData?.contactPage.data.attributes;

    return (
        <>
            <Helmet>
                <title>{t('ContactPage.pageTitle')}</title>
            </Helmet>
            <Layout>
                <SubPage
                    loading={loading}
                    error={infoError || employeesError}
                    title={t('ContactPage.pageTitle').toString()}
                >
                    <div className={s.container}>
                        <iframe
                            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1045.324185695176!2d6.160749877615303!3d50.807668734774886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c0994042ed5bbd%3A0x312e9ef6bc4e6242!2sOptic-Handel%20Fragstein!5e0!3m2!1sde!2sde!4v1699985066618!5m2!1sde!2sde'
                            className={s.map}
                            title='location-map'
                            loading='lazy'
                            lang={'de'}
                        />

                        <ul className={s.contactList}>
                            <h2 className={s.contactListHeader}>{t('ContactPage.contactUs')}</h2>

                            <li className={s.contactListItem}>
                                <h3 className={s.contactItemTitle}>{contactInfo?.address_label}</h3>
                                <p
                                    className={s.contactItemDescription}
                                    dangerouslySetInnerHTML={{
                                        __html: sanitize((contactInfo?.address ?? '').replace(/\n/g, '<br />')),
                                    }}
                                />
                            </li>

                            {contactInfo?.opening_hours_info && (
                                <li className={s.contactListItem}>
                                    <h3 className={s.contactItemTitle}>{contactInfo?.opening_hours_label}</h3>
                                    <div
                                        className={s.contactItemDescription}
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(contactInfo.opening_hours_info),
                                        }}
                                    />
                                </li>
                            )}

                            <li className={s.contactListItem}>
                                <h3 className={s.contactItemTitle}>{contactInfo?.phone_label}</h3>
                                <p className={s.contactItemDescription}>{contactInfo?.phone}</p>
                            </li>

                            <li className={s.contactListItem}>
                                <h3 className={s.contactItemTitle}>{contactInfo?.email_label}</h3>
                                <p className={s.contactItemDescription}>{contactInfo?.email}</p>
                            </li>
                        </ul>

                        <div className={s.personnelContainer}>
                            <ul className={s.personnelTypeList}>
                                <PersonnelList name={t('ContactPage.salesTitle')}>
                                    {employeesData?.contactPageEmployees.data.map((employee) => {
                                        if (employee.attributes.Role === EmployeeRolesEnum.Sales) {
                                            return (
                                                <PersonnelListItem
                                                    key={employee.id}
                                                    fullName={employee.attributes.full_name}
                                                    info={employee.attributes.info}
                                                />
                                            );
                                        }

                                        return null;
                                    })}
                                </PersonnelList>

                                <PersonnelList name={t('ContactPage.technicTitle')}>
                                    {employeesData?.contactPageEmployees.data.map((employee) => {
                                        if (employee.attributes.Role === EmployeeRolesEnum.TechnicianAndService) {
                                            return (
                                                <PersonnelListItem
                                                    key={employee.id}
                                                    fullName={employee.attributes.full_name}
                                                    info={employee.attributes.info}
                                                />
                                            );
                                        }

                                        return null;
                                    })}
                                </PersonnelList>
                            </ul>
                        </div>
                    </div>
                </SubPage>
            </Layout>
        </>
    );
};

export default Contact;
