import React from 'react';
import { addStrapiUrl } from 'utils/strapiUtils';
import s from './EventsListItem.module.scss';
import dayjs from 'dayjs';
import { Chip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import BeanButton from 'components/BeanButton/BeanButton';
import { useTranslation } from 'react-i18next';
import { GetEventsQuery } from 'gql/graphql';

interface Props {
    event: NonNullable<NonNullable<GetEventsQuery['events']>['data']>[0];
}

const EventsListItem = ({ event }: Props) => {
    const { t } = useTranslation('EventsPage');

    return (
        <li className={[s.container, event.attributes?.image?.data ? '' : s.noImage].join(' ')}>
            <div className={s.textContainer}>
                <div>
                    <h4 className={s.title}>{event.attributes?.title}</h4>
                    {event.attributes?.date_start && (
                        <div className={s.date}>
                            <Chip
                                label={`${dayjs(event.attributes.date_start).format('DD.MM.YYYY')} ${
                                    event.attributes.date_end
                                        ? '- ' + dayjs(event.attributes.date_end).format('DD.MM.YYYY')
                                        : ''
                                }`}
                                icon={<FontAwesomeIcon icon={faCalendarDays} size='lg' />}
                                sx={{ padding: '.5rem' }}
                            />
                        </div>
                    )}
                </div>
                <p className={s.description}>{event.attributes?.description}</p>
                <BeanButton variant='contained' link={`${event.id}`} text={t('details')} fullWidth />
            </div>
            {event.attributes?.image?.data && (
                <div className={s.imageContainer}>
                    <img
                        className={s.image}
                        src={addStrapiUrl(event.attributes?.image?.data?.attributes?.url || '')}
                        alt={event.attributes?.image?.data?.attributes?.alternativeText || ''}
                    />
                </div>
            )}
        </li>
    );
};

export default EventsListItem;
