import { faGear, faMagnifyingGlass, faRetweet, faShop, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, IconButton, Modal, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';

import s from './SearchDialog.module.scss';
import { useDebounce } from 'hooks/useDebounce';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { LanguageContext } from 'contexts/LanguageContext';
import { GetProductsByNameQuery, GetUsedProductsByNameQuery } from 'gql/graphql';
import { addStrapiUrl } from 'utils/strapiUtils';
import { NavigationNames } from 'components/Layout/Navbar/Navbar';
import { useTranslation } from 'react-i18next';

const GET_SEARCHED_PRODUCTS = gql`
    query GetProductsByName($name: String!, $locale: I18NLocaleCode!) {
        products(
            locale: $locale
            pagination: { limit: 20 }
            filters: { or: [{ name: { containsi: $name } }, { subcategory: { name: { containsi: $name } } }] }
        ) {
            data {
                id
                attributes {
                    name
                    url
                    images {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    subcategory {
                        data {
                            id
                            attributes {
                                url
                                category {
                                    data {
                                        id
                                        attributes {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const GET_SEARCHED_USED_PRODUCTS = gql`
    query GetUsedProductsByName($name: String!, $locale: I18NLocaleCode!) {
        usedProducts(
            locale: $locale
            pagination: { limit: 20 }
            filters: { or: [{ name: { containsi: $name } }, { subcategory: { name: { containsi: $name } } }] }
        ) {
            data {
                id
                attributes {
                    name
                    url
                    images {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    subcategory {
                        data {
                            id
                            attributes {
                                url
                                category {
                                    data {
                                        id
                                        attributes {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

interface Props {
    open: boolean;
    handleClose: () => void;
}

type SearchMode = 'products' | 'usedProducts';

const SearchDialog = ({ open, handleClose }: Props) => {
    const { language } = useContext(LanguageContext);
    const { t } = useTranslation('SearchDialog');

    const [searchMode, setSearchMode] = useState<SearchMode>('products');
    const [searchValue, setSearchValue] = useState<string>('');

    const debouncedSearchValue = useDebounce(searchValue, 300);

    const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const handleSearchModeChange = (value: SearchMode) => {
        if (value !== null) {
            setSearchMode(value);
        }
    };

    const { data: searchedProducts } = useQuery<GetProductsByNameQuery>(GET_SEARCHED_PRODUCTS, {
        variables: { name: debouncedSearchValue, locale: language.localeCode },
        skip: searchMode !== 'products',
    });

    const { data: searchedUsedProducts } = useQuery<GetUsedProductsByNameQuery>(GET_SEARCHED_USED_PRODUCTS, {
        variables: { name: debouncedSearchValue, locale: language.localeCode },
        skip: searchMode !== 'usedProducts',
    });

    return (
        <>
            <Modal onClose={handleClose} open={open}>
                <Box className={s.dialogContainer}>
                    <div className={s.dialogTitle}>
                        <FontAwesomeIcon size='lg' icon={faMagnifyingGlass} />
                        <TextField
                            className={s.input}
                            placeholder={t('searchPlaceholder') ?? 'Suchen...'}
                            autoComplete='off'
                            value={searchValue}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchValueChange(e)}
                            variant='standard'
                            size='small'
                            fullWidth
                        />
                        <IconButton color='secondary' onClick={handleClose}>
                            <FontAwesomeIcon icon={faXmark} />
                        </IconButton>
                    </div>

                    <div className={s.searchModeContainer}>
                        <Typography>{t('searchIn')}</Typography>

                        <ToggleButtonGroup
                            size='small'
                            color='primary'
                            value={searchMode}
                            exclusive
                            onChange={(event, value) => handleSearchModeChange(value as SearchMode)}
                            aria-label='text alignment'
                        >
                            <ToggleButton value='products' aria-label='products search' className={s.toggleButton}>
                                <FontAwesomeIcon icon={faGear} /> {t('productsSearchLabel')}
                            </ToggleButton>
                            <ToggleButton
                                value='usedProducts'
                                aria-label='used products search'
                                className={s.toggleButton}
                            >
                                <FontAwesomeIcon icon={faRetweet} /> {t('usedProductsSearchLabel')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>

                    <Divider className={s.divider} />

                    <ul className={s.searchList}>
                        {searchMode === 'products'
                            ? searchedProducts?.products?.data?.map((product) => (
                                  <li className={s.searchListItem} key={`product-${product.id}`}>
                                      <Link
                                          className={s.searchListItemLink}
                                          to={`${NavigationNames.Products}/${product.attributes?.subcategory?.data?.attributes?.category?.data?.id}/${product.attributes?.subcategory?.data?.id}/${product.id}`}
                                      >
                                          <img
                                              className={s.image}
                                              width={75}
                                              height={75}
                                              src={
                                                  addStrapiUrl(product.attributes?.images?.data[0].attributes?.url) ??
                                                  ''
                                              }
                                              alt={
                                                  product.attributes?.images?.data[0].attributes?.alternativeText ?? ''
                                              }
                                          />
                                          <Typography variant='body2'>{product.attributes?.name}</Typography>
                                      </Link>
                                  </li>
                              ))
                            : searchedUsedProducts?.usedProducts?.data?.map((product) => (
                                  <li className={s.searchListItem} key={`used-product-${product.id}`}>
                                      <Link
                                          className={s.searchListItemLink}
                                          to={`${NavigationNames.UsedProducts}/${product.attributes?.subcategory?.data?.attributes?.category?.data?.id}/${product.attributes?.subcategory?.data?.id}/${product.id}`}
                                      >
                                          <img
                                              className={s.image}
                                              width={75}
                                              height={75}
                                              src={
                                                  addStrapiUrl(product.attributes?.images?.data[0].attributes?.url) ??
                                                  ''
                                              }
                                              alt={
                                                  product.attributes?.images?.data[0].attributes?.alternativeText ?? ''
                                              }
                                          />
                                          <Typography variant='body2'>{product.attributes?.name}</Typography>
                                      </Link>
                                  </li>
                              ))}
                    </ul>
                </Box>
            </Modal>
        </>
    );
};

export default SearchDialog;
