import React from 'react';
import s from './ProductBadge.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
    type: 'new' | 'sold' | 'reserved';
};

const ProductBadge: React.FC<Props> = ({ type }) => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language as 'en' | 'de';

    // Define translations directly in the component
    const translations = {
        en: {
            new: 'New',
            sold: 'Sold',
            reserved: 'Reserved',
        },
        de: {
            new: 'Neu',
            sold: 'Verkauft',
            reserved: 'Reserviert',
        },
    };

    // Get the correct label based on the type and current language
    const label = translations[currentLanguage]?.[type] ?? translations.de[type];

    return <div className={`${s.badge} ${s[type]}`}>{label}</div>;
};

export default ProductBadge;
